import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import React, {Component} from "react";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import * as actions from "../../redux/actions";
import FormControl from "@material-ui/core/FormControl";


const useStyles = theme => ({
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        maxWidth: '600px', // Fix IE 11 issue.
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    textField: {
        marginTop: theme.spacing(2),
        color: "#ff0000",
    }
});


class PostNews extends Component {
    render() {
        this.handleSubmit = function(e) {
            e.preventDefault();

            if (((!state.announcements.new_announcement_title_field || !state.announcements.new_announcement_field) && !state.matches.matchView.match_id) ||
                ((!state.announcements.new_announcement_field) && state.matches.matchView.match_id))
            {
                onSubmittingAnnouncement();
                return
            }
            // Set application state before submitting
            onNewAnnouncement({title: state.announcements.new_announcement_title_field, text: state.announcements.new_announcement_field, match: undefined});
            onSubmittingAnnouncement();
        };
        const { state, handleNewAnnouncementChange, handleNewAnnouncementTitleChange,onSubmittingAnnouncement, onNewAnnouncement } = this.props;
        const { classes } = this.props;

        return (
            <form className={classes.form}>
                {!state.matches.matchView.match_id && (
                    <TextField
                        error={!state.announcements.new_announcement_title_field && state.announcements.submitted_once}
                        className={classes.textField}
                        variant="outlined"
                        rows="1"
                        margin="dense"
                        required
                        fullWidth
                        rowsMax="10"
                        multiline
                        id="new-announcement"
                        label="Title"
                        name="new-announcement"
                        autoFocus
                        value={state.announcements.new_announcement_title_field}
                        onChange={(e) => handleNewAnnouncementTitleChange(e.target.value)}>
                    </TextField>)}
                    <TextField
                        error={!state.announcements.new_announcement_field && state.announcements.submitted_once}
                        className={classes.textField}
                        variant="outlined"
                        margin="dense"
                        required
                        fullWidth
                        rowsMax={!state.matches.matchView.match_id ? '10' : '5'}
                        rows={!state.matches.matchView.match_id ? '10' : '5'}
                        multiline
                        id="new-announcement"
                        label={!state.matches.matchView.match_id ? 'Announcement' : 'Comment'}
                        name="new-announcement"
                        value={state.announcements.new_announcement_field}
                        onChange={(e) => handleNewAnnouncementChange(e.target.value)}>
                    </TextField>
                <Button
                    fullWidth={!state.matches.matchView.match_id}
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes.submit}
                    onClick={this.handleSubmit}
                >
                    {!state.matches.matchView.match_id ? 'Post Announcement' : 'Post Comment'}
                </Button>
            </form>
        )
    }
}
const styled = withStyles(useStyles)(PostNews);
export default connect(state => { return {state: state} }, {...actions})(styled);