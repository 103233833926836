export const d_map_name = 0          ;
export const d_teamplay = 1          ;
export const d_winner = 2            ;
export const d_matchtag = 3          ;
export const d_avg_pos_x = 4         ;
export const d_avg_pos_y = 5         ;
export const d_avg_pos_z = 6         ;
export const d_a_avg_pos_x = 7       ;
export const d_a_avg_pos_y = 8       ;
export const d_a_avg_pos_z = 9       ;
export const d_s_avg_pos_x = 10      ;
export const d_s_avg_pos_y = 11      ;
export const d_s_avg_pos_z = 12      ;
export const d_r_avg_pos_x = 13      ;
export const d_r_avg_pos_y = 14      ;
export const d_r_avg_pos_z = 15      ;
export const d_l_avg_pos_x = 16      ;
export const d_l_avg_pos_y = 17      ;
export const d_l_avg_pos_z = 18      ;
export const d_w_avg_pos_x = 19      ;
export const d_w_avg_pos_y = 20      ;
export const d_w_avg_pos_z = 21      ;
export const d_a_time = 22           ;
export const d_s_time = 23           ;
export const d_r_time = 24           ;
export const d_l_time = 25           ;
export const d_w_time = 26           ;
export const d_team = 27             ;
export const d_name = 28             ;
export const d_score = 29            ;
export const d_deaths = 30           ;
export const d_effi = 31             ;
export const d_taken = 32            ;
export const d_given = 33            ;
export const d_tmdmg = 34            ;
export const d_self = 35             ;
export const d_todeath = 36          ;
export const d_ewep = 37             ;
export const d_m1_avg_pos_x = 38     ;
export const d_m1_avg_pos_y = 39     ;
export const d_m1_avg_pos_z = 40     ;
export const d_m1_a_avg_pos_x = 41   ;
export const d_m1_a_avg_pos_y = 42   ;
export const d_m1_a_avg_pos_z = 43   ;
export const d_m1_w_avg_pos_x = 44   ;
export const d_m1_w_avg_pos_y = 45   ;
export const d_m1_w_avg_pos_z = 46   ;
export const d_m1_r_avg_pos_x = 47   ;
export const d_m1_r_avg_pos_y = 48   ;
export const d_m1_r_avg_pos_z = 49   ;
export const d_m1_l_avg_pos_x = 50   ;
export const d_m1_l_avg_pos_y = 51   ;
export const d_m1_l_avg_pos_z = 52   ;
export const d_m1_s_avg_pos_x = 53   ;
export const d_m1_s_avg_pos_y = 54   ;
export const d_m1_s_avg_pos_z = 55   ;
export const d_m2_avg_pos_x = 56     ;
export const d_m2_avg_pos_y = 57     ;
export const d_m2_avg_pos_z = 58     ;
export const d_m2_a_avg_pos_x = 59   ;
export const d_m2_a_avg_pos_y = 60   ;
export const d_m2_a_avg_pos_z = 61   ;
export const d_m2_w_avg_pos_x = 62   ;
export const d_m2_w_avg_pos_y = 63   ;
export const d_m2_w_avg_pos_z = 64   ;
export const d_m2_r_avg_pos_x = 65   ;
export const d_m2_r_avg_pos_y = 66   ;
export const d_m2_r_avg_pos_z = 67   ;
export const d_m2_l_avg_pos_x = 68   ;
export const d_m2_l_avg_pos_y = 69   ;
export const d_m2_l_avg_pos_z = 70   ;
export const d_m2_s_avg_pos_x = 71   ;
export const d_m2_s_avg_pos_y = 72   ;
export const d_m2_s_avg_pos_z = 73   ;
export const d_m3_avg_pos_x = 74     ;
export const d_m3_avg_pos_y = 75     ;
export const d_m3_avg_pos_z = 76     ;
export const d_m3_a_avg_pos_x = 77   ;
export const d_m3_a_avg_pos_y = 78   ;
export const d_m3_a_avg_pos_z = 79   ;
export const d_m3_w_avg_pos_x = 80   ;
export const d_m3_w_avg_pos_y = 81   ;
export const d_m3_w_avg_pos_z = 82   ;
export const d_m3_r_avg_pos_x = 83   ;
export const d_m3_r_avg_pos_y = 84   ;
export const d_m3_r_avg_pos_z = 85   ;
export const d_m3_l_avg_pos_x = 86   ;
export const d_m3_l_avg_pos_y = 87   ;
export const d_m3_l_avg_pos_z = 88   ;
export const d_m3_s_avg_pos_x = 89   ;
export const d_m3_s_avg_pos_y = 90   ;
export const d_m3_s_avg_pos_z = 91   ;
export const d_m4_avg_pos_x = 92     ;
export const d_m4_avg_pos_y = 93     ;
export const d_m4_avg_pos_z = 94     ;
export const d_m4_a_avg_pos_x = 95   ;
export const d_m4_a_avg_pos_y = 96   ;
export const d_m4_a_avg_pos_z = 97   ;
export const d_m4_w_avg_pos_x = 98   ;
export const d_m4_w_avg_pos_y = 99   ;
export const d_m4_w_avg_pos_z = 100  ;
export const d_m4_r_avg_pos_x = 101  ;
export const d_m4_r_avg_pos_y = 102  ;
export const d_m4_r_avg_pos_z = 103  ;
export const d_m4_l_avg_pos_x = 104  ;
export const d_m4_l_avg_pos_y = 105  ;
export const d_m4_l_avg_pos_z = 106  ;
export const d_m4_s_avg_pos_x = 107  ;
export const d_m4_s_avg_pos_y = 108  ;
export const d_m4_s_avg_pos_z = 109  ;
export const d_m5_avg_pos_x = 110    ;
export const d_m5_avg_pos_y = 111    ;
export const d_m5_avg_pos_z = 112    ;
export const d_m5_a_avg_pos_x = 113  ;
export const d_m5_a_avg_pos_y = 114  ;
export const d_m5_a_avg_pos_z = 115  ;
export const d_m5_w_avg_pos_x = 116  ;
export const d_m5_w_avg_pos_y = 117  ;
export const d_m5_w_avg_pos_z = 118  ;
export const d_m5_r_avg_pos_x = 119  ;
export const d_m5_r_avg_pos_y = 120  ;
export const d_m5_r_avg_pos_z = 121  ;
export const d_m5_l_avg_pos_x = 122  ;
export const d_m5_l_avg_pos_y = 123  ;
export const d_m5_l_avg_pos_z = 124  ;
export const d_m5_s_avg_pos_x = 125  ;
export const d_m5_s_avg_pos_y = 126  ;
export const d_m5_s_avg_pos_z = 127  ;
export const d_m6_avg_pos_x = 128    ;
export const d_m6_avg_pos_y = 129    ;
export const d_m6_avg_pos_z = 130    ;
export const d_m6_a_avg_pos_x = 131  ;
export const d_m6_a_avg_pos_y = 132  ;
export const d_m6_a_avg_pos_z = 133  ;
export const d_m6_w_avg_pos_x = 134  ;
export const d_m6_w_avg_pos_y = 135  ;
export const d_m6_w_avg_pos_z = 136  ;
export const d_m6_r_avg_pos_x = 137  ;
export const d_m6_r_avg_pos_y = 138  ;
export const d_m6_r_avg_pos_z = 139  ;
export const d_m6_l_avg_pos_x = 140  ;
export const d_m6_l_avg_pos_y = 141  ;
export const d_m6_l_avg_pos_z = 142  ;
export const d_m6_s_avg_pos_x = 143  ;
export const d_m6_s_avg_pos_y = 144  ;
export const d_m6_s_avg_pos_z = 145  ;
export const d_m7_avg_pos_x = 146    ;
export const d_m7_avg_pos_y = 147    ;
export const d_m7_avg_pos_z = 148    ;
export const d_m7_a_avg_pos_x = 149  ;
export const d_m7_a_avg_pos_y = 150  ;
export const d_m7_a_avg_pos_z = 151  ;
export const d_m7_w_avg_pos_x = 152  ;
export const d_m7_w_avg_pos_y = 153  ;
export const d_m7_w_avg_pos_z = 154  ;
export const d_m7_r_avg_pos_x = 155  ;
export const d_m7_r_avg_pos_y = 156  ;
export const d_m7_r_avg_pos_z = 157  ;
export const d_m7_l_avg_pos_x = 158  ;
export const d_m7_l_avg_pos_y = 159  ;
export const d_m7_l_avg_pos_z = 160  ;
export const d_m7_s_avg_pos_x = 161  ;
export const d_m7_s_avg_pos_y = 162  ;
export const d_m7_s_avg_pos_z = 163  ;
export const d_m8_avg_pos_x = 164    ;
export const d_m8_avg_pos_y = 165    ;
export const d_m8_avg_pos_z = 166    ;
export const d_m8_a_avg_pos_x = 167  ;
export const d_m8_a_avg_pos_y = 168  ;
export const d_m8_a_avg_pos_z = 169  ;
export const d_m8_w_avg_pos_x = 170  ;
export const d_m8_w_avg_pos_y = 171  ;
export const d_m8_w_avg_pos_z = 172  ;
export const d_m8_r_avg_pos_x = 173  ;
export const d_m8_r_avg_pos_y = 174  ;
export const d_m8_r_avg_pos_z = 175  ;
export const d_m8_l_avg_pos_x = 176  ;
export const d_m8_l_avg_pos_y = 177  ;
export const d_m8_l_avg_pos_z = 178  ;
export const d_m8_s_avg_pos_x = 179  ;
export const d_m8_s_avg_pos_y = 180  ;
export const d_m8_s_avg_pos_z = 181  ;
export const d_m9_avg_pos_x = 182    ;
export const d_m9_avg_pos_y = 183    ;
export const d_m9_avg_pos_z = 184    ;
export const d_m9_a_avg_pos_x = 185  ;
export const d_m9_a_avg_pos_y = 186  ;
export const d_m9_a_avg_pos_z = 187  ;
export const d_m9_w_avg_pos_x = 188  ;
export const d_m9_w_avg_pos_y = 189  ;
export const d_m9_w_avg_pos_z = 190  ;
export const d_m9_r_avg_pos_x = 191  ;
export const d_m9_r_avg_pos_y = 192  ;
export const d_m9_r_avg_pos_z = 193  ;
export const d_m9_l_avg_pos_x = 194  ;
export const d_m9_l_avg_pos_y = 195  ;
export const d_m9_l_avg_pos_z = 196  ;
export const d_m9_s_avg_pos_x = 197  ;
export const d_m9_s_avg_pos_y = 198  ;
export const d_m9_s_avg_pos_z = 199  ;
export const d_m10_avg_pos_x = 200   ;
export const d_m10_avg_pos_y = 201   ;
export const d_m10_avg_pos_z = 202   ;
export const d_m10_a_avg_pos_x = 203 ;
export const d_m10_a_avg_pos_y = 204 ;
export const d_m10_a_avg_pos_z = 205 ;
export const d_m10_w_avg_pos_x = 206 ;
export const d_m10_w_avg_pos_y = 207 ;
export const d_m10_w_avg_pos_z = 208 ;
export const d_m10_r_avg_pos_x = 209 ;
export const d_m10_r_avg_pos_y = 210 ;
export const d_m10_r_avg_pos_z = 211 ;
export const d_m10_l_avg_pos_x = 212 ;
export const d_m10_l_avg_pos_y = 213 ;
export const d_m10_l_avg_pos_z = 214 ;
export const d_m10_s_avg_pos_x = 215 ;
export const d_m10_s_avg_pos_y = 216 ;
export const d_m10_s_avg_pos_z = 217 ;
export const d_m11_avg_pos_x = 218   ;
export const d_m11_avg_pos_y = 219   ;
export const d_m11_avg_pos_z = 220   ;
export const d_m11_a_avg_pos_x = 221 ;
export const d_m11_a_avg_pos_y = 222 ;
export const d_m11_a_avg_pos_z = 223 ;
export const d_m11_w_avg_pos_x = 224 ;
export const d_m11_w_avg_pos_y = 225 ;
export const d_m11_w_avg_pos_z = 226 ;
export const d_m11_r_avg_pos_x = 227 ;
export const d_m11_r_avg_pos_y = 228 ;
export const d_m11_r_avg_pos_z = 229 ;
export const d_m11_l_avg_pos_x = 230 ;
export const d_m11_l_avg_pos_y = 231 ;
export const d_m11_l_avg_pos_z = 232 ;
export const d_m11_s_avg_pos_x = 233 ;
export const d_m11_s_avg_pos_y = 234 ;
export const d_m11_s_avg_pos_z = 235 ;
export const d_m12_avg_pos_x = 236   ;
export const d_m12_avg_pos_y = 237   ;
export const d_m12_avg_pos_z = 238   ;
export const d_m12_a_avg_pos_x = 239 ;
export const d_m12_a_avg_pos_y = 240 ;
export const d_m12_a_avg_pos_z = 241 ;
export const d_m12_w_avg_pos_x = 242 ;
export const d_m12_w_avg_pos_y = 243 ;
export const d_m12_w_avg_pos_z = 244 ;
export const d_m12_r_avg_pos_x = 245 ;
export const d_m12_r_avg_pos_y = 246 ;
export const d_m12_r_avg_pos_z = 247 ;
export const d_m12_l_avg_pos_x = 248 ;
export const d_m12_l_avg_pos_y = 249 ;
export const d_m12_l_avg_pos_z = 250 ;
export const d_m12_s_avg_pos_x = 251 ;
export const d_m12_s_avg_pos_y = 252 ;
export const d_m12_s_avg_pos_z = 253 ;
export const d_m13_avg_pos_x = 254   ;
export const d_m13_avg_pos_y = 255   ;
export const d_m13_avg_pos_z = 256   ;
export const d_m13_a_avg_pos_x = 257 ;
export const d_m13_a_avg_pos_y = 258 ;
export const d_m13_a_avg_pos_z = 259 ;
export const d_m13_w_avg_pos_x = 260 ;
export const d_m13_w_avg_pos_y = 261 ;
export const d_m13_w_avg_pos_z = 262 ;
export const d_m13_r_avg_pos_x = 263 ;
export const d_m13_r_avg_pos_y = 264 ;
export const d_m13_r_avg_pos_z = 265 ;
export const d_m13_l_avg_pos_x = 266 ;
export const d_m13_l_avg_pos_y = 267 ;
export const d_m13_l_avg_pos_z = 268 ;
export const d_m13_s_avg_pos_x = 269 ;
export const d_m13_s_avg_pos_y = 270 ;
export const d_m13_s_avg_pos_z = 271 ;
export const d_m14_avg_pos_x = 272   ;
export const d_m14_avg_pos_y = 273   ;
export const d_m14_avg_pos_z = 274   ;
export const d_m14_a_avg_pos_x = 275 ;
export const d_m14_a_avg_pos_y = 276 ;
export const d_m14_a_avg_pos_z = 277 ;
export const d_m14_w_avg_pos_x = 278 ;
export const d_m14_w_avg_pos_y = 279 ;
export const d_m14_w_avg_pos_z = 280 ;
export const d_m14_r_avg_pos_x = 281 ;
export const d_m14_r_avg_pos_y = 282 ;
export const d_m14_r_avg_pos_z = 283 ;
export const d_m14_l_avg_pos_x = 284 ;
export const d_m14_l_avg_pos_y = 285 ;
export const d_m14_l_avg_pos_z = 286 ;
export const d_m14_s_avg_pos_x = 287 ;
export const d_m14_s_avg_pos_y = 288 ;
export const d_m14_s_avg_pos_z = 289 ;
export const d_m15_avg_pos_x = 290   ;
export const d_m15_avg_pos_y = 291   ;
export const d_m15_avg_pos_z = 292   ;
export const d_m15_a_avg_pos_x = 293 ;
export const d_m15_a_avg_pos_y = 294 ;
export const d_m15_a_avg_pos_z = 295 ;
export const d_m15_w_avg_pos_x = 296 ;
export const d_m15_w_avg_pos_y = 297 ;
export const d_m15_w_avg_pos_z = 298 ;
export const d_m15_r_avg_pos_x = 299 ;
export const d_m15_r_avg_pos_y = 300 ;
export const d_m15_r_avg_pos_z = 301 ;
export const d_m15_l_avg_pos_x = 302 ;
export const d_m15_l_avg_pos_y = 303 ;
export const d_m15_l_avg_pos_z = 304 ;
export const d_m15_s_avg_pos_x = 305 ;
export const d_m15_s_avg_pos_y = 306 ;
export const d_m15_s_avg_pos_z = 307 ;
export const d_m16_avg_pos_x = 308   ;
export const d_m16_avg_pos_y = 309   ;
export const d_m16_avg_pos_z = 310   ;
export const d_m16_a_avg_pos_x = 311 ;
export const d_m16_a_avg_pos_y = 312 ;
export const d_m16_a_avg_pos_z = 313 ;
export const d_m16_w_avg_pos_x = 314 ;
export const d_m16_w_avg_pos_y = 315 ;
export const d_m16_w_avg_pos_z = 316 ;
export const d_m16_r_avg_pos_x = 317 ;
export const d_m16_r_avg_pos_y = 318 ;
export const d_m16_r_avg_pos_z = 319 ;
export const d_m16_l_avg_pos_x = 320 ;
export const d_m16_l_avg_pos_y = 321 ;
export const d_m16_l_avg_pos_z = 322 ;
export const d_m16_s_avg_pos_x = 323 ;
export const d_m16_s_avg_pos_y = 324 ;
export const d_m16_s_avg_pos_z = 325 ;
export const d_m17_avg_pos_x = 326   ;
export const d_m17_avg_pos_y = 327   ;
export const d_m17_avg_pos_z = 328   ;
export const d_m17_a_avg_pos_x = 329 ;
export const d_m17_a_avg_pos_y = 330 ;
export const d_m17_a_avg_pos_z = 331 ;
export const d_m17_w_avg_pos_x = 332 ;
export const d_m17_w_avg_pos_y = 333 ;
export const d_m17_w_avg_pos_z = 334 ;
export const d_m17_r_avg_pos_x = 335 ;
export const d_m17_r_avg_pos_y = 336 ;
export const d_m17_r_avg_pos_z = 337 ;
export const d_m17_l_avg_pos_x = 338 ;
export const d_m17_l_avg_pos_y = 339 ;
export const d_m17_l_avg_pos_z = 340 ;
export const d_m17_s_avg_pos_x = 341 ;
export const d_m17_s_avg_pos_y = 342 ;
export const d_m17_s_avg_pos_z = 343 ;
export const d_m18_avg_pos_x = 344   ;
export const d_m18_avg_pos_y = 345   ;
export const d_m18_avg_pos_z = 346   ;
export const d_m18_a_avg_pos_x = 347 ;
export const d_m18_a_avg_pos_y = 348 ;
export const d_m18_a_avg_pos_z = 349 ;
export const d_m18_w_avg_pos_x = 350 ;
export const d_m18_w_avg_pos_y = 351 ;
export const d_m18_w_avg_pos_z = 352 ;
export const d_m18_r_avg_pos_x = 353 ;
export const d_m18_r_avg_pos_y = 354 ;
export const d_m18_r_avg_pos_z = 355 ;
export const d_m18_l_avg_pos_x = 356 ;
export const d_m18_l_avg_pos_y = 357 ;
export const d_m18_l_avg_pos_z = 358 ;
export const d_m18_s_avg_pos_x = 359 ;
export const d_m18_s_avg_pos_y = 360 ;
export const d_m18_s_avg_pos_z = 361 ;
export const d_m19_avg_pos_x = 362   ;
export const d_m19_avg_pos_y = 363   ;
export const d_m19_avg_pos_z = 364   ;
export const d_m19_a_avg_pos_x = 365 ;
export const d_m19_a_avg_pos_y = 366 ;
export const d_m19_a_avg_pos_z = 367 ;
export const d_m19_w_avg_pos_x = 368 ;
export const d_m19_w_avg_pos_y = 369 ;
export const d_m19_w_avg_pos_z = 370 ;
export const d_m19_r_avg_pos_x = 371 ;
export const d_m19_r_avg_pos_y = 372 ;
export const d_m19_r_avg_pos_z = 373 ;
export const d_m19_l_avg_pos_x = 374 ;
export const d_m19_l_avg_pos_y = 375 ;
export const d_m19_l_avg_pos_z = 376 ;
export const d_m19_s_avg_pos_x = 377 ;
export const d_m19_s_avg_pos_y = 378 ;
export const d_m19_s_avg_pos_z = 379 ;
export const d_m20_avg_pos_x = 380   ;
export const d_m20_avg_pos_y = 381   ;
export const d_m20_avg_pos_z = 382   ;
export const d_m20_a_avg_pos_x = 383 ;
export const d_m20_a_avg_pos_y = 384 ;
export const d_m20_a_avg_pos_z = 385 ;
export const d_m20_w_avg_pos_x = 386 ;
export const d_m20_w_avg_pos_y = 387 ;
export const d_m20_w_avg_pos_z = 388 ;
export const d_m20_r_avg_pos_x = 389 ;
export const d_m20_r_avg_pos_y = 390 ;
export const d_m20_r_avg_pos_z = 391 ;
export const d_m20_l_avg_pos_x = 392 ;
export const d_m20_l_avg_pos_y = 393 ;
export const d_m20_l_avg_pos_z = 394 ;
export const d_m20_s_avg_pos_x = 395 ;
export const d_m20_s_avg_pos_y = 396 ;
export const d_m20_s_avg_pos_z = 397 ;
export const d_tcolor = 398;
export const d_bcolor = 399;
export const d_rltook = 400;
export const d_rlkill = 401;
export const d_rldrop = 402;
export const d_rlxfer = 403;
export const d_demo_start = 404;