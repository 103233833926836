import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import * as actions from "../redux/actions";

const styles = theme => ({
    root: {

    }
});

class AlertDialog extends Component {

    render() {

        const {open, msg} = this.props.menu.errorDialog;

        const {onErrorDialogClose} = this.props;

        return (
            <div>
                <Dialog
                    open={open}
                    onClose={onErrorDialogClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Something went wrong..."}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {msg}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onErrorDialogClose} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

const styled = withStyles(styles)(AlertDialog);
export default connect(state => { return {menu: state.menu} }, {...actions})(styled);