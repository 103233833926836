import React, {Component, forwardRef, Fragment, useCallback} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import * as actions from "../redux/actions";
import Typography from "@material-ui/core/Typography";
import MaterialTable, {MTableToolbar} from "material-table";
import {
    Add,
    AddBox, AddPhotoAlternate, ArrowUpward, AttachFile,
    Check, ChevronLeft,
    ChevronRight,
    Clear,
    DeleteOutline,
    Edit,
    FilterList,
    FirstPage, LastPage, Remove,
    SaveAlt, Search, ViewColumn
} from "@material-ui/icons";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import {onDeleteMatchMap} from "../redux/actions";
import {DropzoneArea, DropzoneDialog} from "material-ui-dropzone";
import {useDropzone} from "react-dropzone";
import Switch from "@material-ui/core/Switch";
import Grid from "@material-ui/core/Grid";
import Fab from "@material-ui/core/Fab";
import LinearProgress from "@material-ui/core/LinearProgress";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref}/>),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref}/>),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref}/>),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref}/>),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref}/>),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref}/>),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref}/>),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref}/>),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref}/>),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref}/>),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref}/>),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref}/>),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref}/>),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref}/>),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref}/>)
};

const useStyles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    removeBtn: {
        transition: '.5s ease',
        position: 'absolute',
        opacity: 0,
        top: -5,
        right: -5,
        width: 40,
        height: 40
    },
    smallPreviewImg: {
        maxWidth: '80%',
        marginTop: 5,
        marginRight: 10,
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
        boxSizing: 'border-box',
        boxShadow: 'rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px',
        borderRadius: 2,
        zIndex: 5,
        opacity: 1
    },
    imageContainer: {
        position: 'relative',
        zIndex: 10,
        textAlign: 'center',
        '&:hover $smallPreviewImg': {
            opacity: 0.3
        },
        '&:hover $removeBtn': {
            opacity: 1
        }
    }
});

function MyDropzone(props) {

    const {onAddMVD} = props;
    const {added} = props;
    const {onAddSS} = props;
    const {type} = props;


    const onDrop = useCallback(acceptedFiles => {
        if (type === 'SS'){
            onAddSS(acceptedFiles);
        } else {
            onAddMVD(acceptedFiles);
        }
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <div {...getRootProps()} style={{textAlign: 'center', minHeight: '300px'}}>
            <input {...getInputProps()} />
            {
                isDragActive ?
                    <p>Drop the {type === 'SS' ? 'screenshots' : 'demos'} here ...</p> :
                    <p>Drag 'n' drop some {type === 'SS' ? 'screenshots' : 'demos'} here, or click to select {type === 'SS' ? 'image files' : 'mvd files'}.</p>
            }
            {
                type !== 'SS' ?
                    <p>Submit your MVD's one each time if you care about maps display order.</p> :
                    ''
            }
            <br/>
            <br/>
            <Typography variant="h4">{added}</Typography>
        </div>
    )
}

class MatchEdit extends Component {
    render() {

        const {matches, account, classes, onCloseMatchViewEditPopup, onSudoModeToggle, onSaveSS,
            onCloseSSDropZoneDialog, onUpdateMatchMap, onOpenSSDropZoneDialog, onDeleteMatchMap, onAddManual,
            onOpenDropZoneDialog, onCloseDropZoneDialog, onSaveMVD, onAddMVD, onAddSS, onDeleteSS} = this.props;

        const open = matches.matchView.editPopupOpen || false;

        let lookup = {};

        if (open && matches.matchView.loaded) {
            lookup[matches.matchView.match_obj.participant_a.id] = matches.matchView.match_obj.participant_a_name;
            lookup[matches.matchView.match_obj.participant_b.id] = matches.matchView.match_obj.participant_b_name;
        }

        const handleClose = () => {
            onCloseMatchViewEditPopup(false);
        };

        const handleCloseDropZoneDialog = () => {
            onCloseDropZoneDialog();
        };
        const handleSaveMVD = () => {
            handleCloseDropZoneDialog();
            onSaveMVD();
        };
        const handleOpenSSDropZoneDialog = (event, rowData) => {
            onOpenSSDropZoneDialog({matchmap_id: rowData.id})
        };
        const handleCloseSSDropZoneDialog = () => {
            onCloseSSDropZoneDialog();
        };
        const handleSaveSS = () => {
            handleCloseSSDropZoneDialog();
            onSaveSS();
        };
        const handleOpenDropZoneDialog = (event, rowData) => {
            onOpenDropZoneDialog({matchmap_id: rowData.id})
        };
        const handleSudoModeToggle = (event) => {
            if (account.is_tournament_admin){
                onSudoModeToggle()
            }
        };
        const handleDeleteMatchMap = (oldData, resolve) => {
            const matchmap_id = oldData.id ?  oldData.id.toString() : null;
            const mvdmapstat_id = oldData.mvdmapstat && oldData.mvdmapstat.id ? oldData.mvdmapstat.id.toString() : null;
            onDeleteMatchMap({matchmap_id: matchmap_id, mvdmapstat_id: mvdmapstat_id, oldData: oldData});
            resolve();
        };
        const handleUpdateMatchMap = (newData, oldData, resolve, reject) => {
            //onUpdatingMatchMap();
            onUpdateMatchMap(oldData, newData, resolve, reject);
        };
        const handleAddManual = (event, rowData) => {
            onAddManual();
        };
        const handleDeleteSS = (ss_obj) => {
            return (e) => {
                e.preventDefault();
                onDeleteSS(ss_obj);
            }
        };


        return (
            <React.Fragment>
                <Dialog
                    fullWidth={true}
                    maxWidth='lg'
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="max-width-dialog-title"
                >
                    <DialogTitle style={{textAlign: 'center'}} id="max-width-dialog-title" disableTypography={true}>
                        <Typography variant="h5" className={classes.title}>
                            Match #{matches.matchView.match_id}<br/></Typography>
                        <Typography variant="h5"
                                    className={classes.title}>{matches.matchView.match_obj ? (matches.matchView.match_obj.games_a > matches.matchView.match_obj.games_b ? matches.matchView.match_obj.participant_a_name + ' ' + (matches.matchView.match_obj.games_a || '0') : matches.matchView.match_obj.participant_b_name + ' ' + (matches.matchView.match_obj.games_b  || '0')) + ' ' : ''}
                            vs
                            {matches.matchView.match_obj ? ' ' + (matches.matchView.match_obj.games_a > matches.matchView.match_obj.games_b ? (matches.matchView.match_obj.games_b  || '0') + ' ' + matches.matchView.match_obj.participant_b_name : (matches.matchView.match_obj.games_a || '0') + ' ' + matches.matchView.match_obj.participant_a_name) : ''}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        {matches.matchView.loaded && matches.matchView.editPopupOpen && (
                            <Fragment>
                                <MaterialTable
                                    editable={{
                                        isEditable: rowData => matches.matchView.editPopupState.sudoMode || !matches.matchView.match_obj.confirmed, // only name(a) rows would be editable
                                        isDeletable: rowData => matches.matchView.editPopupState.sudoMode || !matches.matchView.match_obj.confirmed, // only name(a) rows would be deletable
                                        onRowUpdate: (newData, oldData) =>
                                            new Promise((resolve, reject) => {
                                                handleUpdateMatchMap(newData, oldData, resolve, reject)
                                            }),
                                        onRowDelete: oldData =>
                                            new Promise((resolve, reject) => {
                                                handleDeleteMatchMap(oldData, resolve)
                                            })
                                    }}
                                    icons={tableIcons}
                                    title="Edit Report"
                                    columns={matches.matchView.editPopupState.sudoMode ? matches.matchView.editPopupState.sudoColumns : matches.matchView.editPopupState.columns}
                                    data={matches.matchView.matchMaps}
                                    actions={[
                                        {
                                            disabled: matches.matchView.match_obj.confirmed,
                                            icon: AttachFile,
                                            tooltip: !matches.matchView.match_obj.confirmed ? 'Upload Demos' : null,
                                            isFreeAction: true,
                                            onClick: (event, rowData) => handleOpenDropZoneDialog(event, rowData)
                                        },
                                        {
                                            disabled: !matches.matchView.editPopupState.sudoMode,
                                            icon: Add,
                                            tooltip: matches.matchView.editPopupState.sudoMode ? 'Add Non-MVD Report' : null,
                                            isFreeAction: true,
                                            onClick: (event, rowData) => handleAddManual(event, rowData)
                                        },
                                        {
                                            disabled: matches.matchView.match_obj.confirmed && !matches.matchView.editPopupState.sudoMode,
                                            icon: AddPhotoAlternate,
                                            tooltip: !matches.matchView.match_obj.confirmed || matches.matchView.editPopupState.sudoMode ? 'Upload Screenshots' : null,
                                            isFreeAction: true,
                                            onClick: (event, rowData) => handleOpenSSDropZoneDialog(event, rowData)
                                        }
                                    ]}
                                    components={{
                                        Toolbar: props => (
                                            <div>
                                                <MTableToolbar {...props} />
                                                <div style={{padding: '0px 10px'}}>
                                                    <Switch
                                                        disabled={!account.is_tournament_admin}
                                                        checked={matches.matchView.editPopupState.sudoMode}
                                                        onChange={handleSudoModeToggle}
                                                        value="sudo"
                                                        color="primary"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                    /> Sudo Mode
                                                </div>
                                            </div>
                                        )
                                    }}
                                    options={{
                                        search: false,
                                        sorting: false,
                                        paging: false,
                                        draggable: false,
                                        rowStyle: {padding: '0px, 0px, 0px, 0px'}
                                    }}

                                />
                                <Dialog
                                    fullWidth={true}
                                    maxWidth='md'
                                    open={matches.matchView.editPopupState.dropZoneDialogOpen}
                                    onClose={handleClose}
                                    aria-labelledby="max-width-dialog-title"
                                >
                                    <DialogContent>
                                    <MyDropzone type='MVD' onAddMVD={onAddMVD} onAddSS={{}} added={matches.matchView.editPopupState.files.length > 0 ? matches.matchView.editPopupState.files.map(e => e.name).join(", ") : ''}/>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleSaveMVD} variant="contained" color="primary">
                                            Save
                                        </Button>
                                        <Button onClick={handleCloseDropZoneDialog} variant="contained" color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    fullWidth={true}
                                    maxWidth='md'
                                    open={matches.matchView.editPopupState.ssDropZoneDialogOpen}
                                    onClose={handleClose}
                                    aria-labelledby="max-width-dialog-title"
                                >
                                    <DialogContent>
                                        <MyDropzone type='SS' onAddMVD={{}} onAddSS={onAddSS} added={matches.matchView.editPopupState.ssFiles.length > 0 ? matches.matchView.editPopupState.ssFiles.map(e => e.name).join(", ") : ''}/>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleSaveSS} variant="contained" color="primary">
                                            Save
                                        </Button>
                                        <Button onClick={handleCloseSSDropZoneDialog} variant="contained" color="primary">
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Grid style={{marginTop: '10px'}} container spacing={1}>
                                {
                                    matches.matchView.match_obj && matches.matchView.match_obj.screenshot_set && matches.matchView.match_obj.screenshot_set.length > 0 && matches.matchView.match_obj.screenshot_set.map((fileObject, i) => {
                                        if (!!fileObject.ss) {
                                            const img = (fileObject.ss ?
                                                    <img className={classes.smallPreviewImg} role="presentation" src={fileObject.ss.replace('http', 'https').replace(':8000', '')}/>
                                                    :
                                                    <AttachFileIcon className={classes.smallPreviewImg} />
                                            );
                                            return (
                                                <Grid item xs={1} key={i} className={classes.imageContainer}>
                                                    {img}
                                                    <Fab onClick={handleDeleteSS(fileObject)}
                                                         aria-label="Delete"
                                                         className={classes.removeBtn}>
                                                        <DeleteOutline />
                                                    </Fab>
                                                    <p>{fileObject.ss.split('/')[-1]}</p>
                                                </Grid>
                                            );
                                        } else {
                                            return <Fragment />
                                        }
                                    })
                                }
                                </Grid>
                                <Typography variant="subtitle2">{matches.matchView.editPopupState.activeMsg}</Typography>
                                {/*{matches.matchView.editPopupOpen && !matches.matchView.editPopupState.loaded && (<LinearProgress/>)}*/}
                            </Fragment>
                        )}
                        {matches.matchView.editPopupOpen && !matches.matchView.editPopupState.loaded && (
                            <Fragment>
                                <LinearProgress/>
                            </Fragment>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button style={{marginTop: '30px'}} onClick={handleClose} variant="contained" color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const styled = withStyles(useStyles)(MatchEdit);
export default connect(state => {
    return {matches: state.matches, account: state.account}
}, {...actions})(styled);