import React from 'react';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import { withStyles } from '@material-ui/core/styles';
import EventItem from './EventItem';

const styles = {
    root: {
        maxWidth: 600,
        width: '100%'
    },
};

const EventListView = ({ events = [], classes }) => (
    <Card className={classes.root}>
        <List >
            {events.map((event, index) => (
                <EventItem event={event} key={event.id} index={index} />
            ))}
        </List>
    </Card>
);

const EventList = withStyles(styles)(EventListView);

export default EventList;
