import React, {Fragment} from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { withStyles } from '@material-ui/core/styles';
import Avatar from './Avatar';
import {Typography} from "@material-ui/core";

const styles = {
    listItemOdd: {
        alignItems: 'normal',
        backgroundColor: "#4f4f4f" +
            ""
    },
    listItemEven: {
        alignItems: 'normal',
        backgroundColor: "#424242"

    }

};

const EventItemView = ({ event, classes, index }) => (
    <ListItem className={index % 2 === 0 ? classes.listItemEven : classes.listItemOdd }>
        <ListItemAvatar>
            <Avatar user={event.user} />
        </ListItemAvatar>
        <ListItemText
            primary={
                <div className={classes.truncate}>
                    <strong>
                        {event.user ? event.user : 'Anonymous'}
                    </strong>{': '}<span style={{fontStyle: 'italic'}}>{event.title}</span> <br />
                    <br />
                    {event.text.split('\n').map((item, key) => {
                        return <Fragment key={key}>{item}<br/></Fragment>
                    })
                    }
                    <br/>
                </div>
            }
            secondary={new Date(event.datetime).toLocaleString()}
        />
    </ListItem>
);

const EventItem = withStyles(styles)(EventItemView);

export default EventItem;
