import {applyMiddleware, createStore, compose} from 'redux';
import getQuad from './reducers';
import thunk from 'redux-thunk';

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const existingToken = localStorage.getItem('auth_token') ? localStorage.getItem('auth_token') : '';
const existingUserID = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
const existingUserName = localStorage.getItem('user_name') ? localStorage.getItem('user_name') : '';
const existingEmail = localStorage.getItem('user_email') ? localStorage.getItem('user_email') : '';
const existingTournamentAdmin = localStorage.getItem('is_tournament_admin') ? localStorage.getItem('is_tournament_admin') : '';
const existingCaptain = localStorage.getItem('is_captain') ? localStorage.getItem('is_captain') : '';
const existingProfile = localStorage.getItem('profile') ? localStorage.getItem('profile') : '{}';



const defaultState = {
    // account functionality / application state
    account: {
        user_id: existingUserID,
        auth_token: existingToken,
        user_name: existingUserName,
        user_email: existingEmail,
        is_tournament_admin: existingTournamentAdmin,
        is_captain: existingCaptain,
        profile: JSON.parse(existingProfile),
        loaded: false,
        messages: [],
        old_password: '',
        new_password: '',
        login_user: '',
        login_password: '',
        reg_user: '',
        reg_password: '',
        reg_email: '',
        reg_team: '',
        reg_player: '',
        recaptcha: '',

    },
    // matches data / application state
    matches: {
        objects: [],
        loaded: false,
        stages_loaded: false,
        messages: [],
        matchView: {
            match_obj: {},
            match_id: '',
            popupOpen: false,
            editMode: false,
            canEdit: false,
            loaded: false,
            inDrama: false,
            matchMaps: [],
            editPopupOpen: false,
            editPopupState: {
                loaded: false,
                sudoMode: false,
                dropZoneDialogOpen: false,
                ssDropZoneDialogOpen: false,
                activeDropzoneMatchMap: '',
                files: [],
                ssFiles: [],
                activeMsg: ''

            }
        },

    },
    // menu data / application state
    menu: {
        menuOpen: {},
        activeContent: {
            type: '',
            payload: '' },
        messages: [],
        anchorEl: null,
        activeSeason: "7", //requires event_id
        activeSeasonName: "Season 4",
        errorDialog: {
            open: false,
            msg: ''
        }
    },
    // news posts data / application state
    announcements: {
        items: [
            // {
            //     id: 1234,
            //     label: 'modified post "Hello World"',
            //     createdAt: '2019-01-10T17:15:56.000Z',
            //     author: {
            //         name: 'John Doe',
            //         email: 'jitewaboh@lagify.com',
            //     },
            // },
            ],
        active_match: undefined,
        count: undefined,
        loaded: false,
        new_announcement_field: '',
        new_announcement_title_field: '',
        submitted_once: false
    },
    participants: {
        loaded: false,
        objects: []
    },
    // General loading state
    loaded: false,
};

const store = createStore(getQuad, defaultState, storeEnhancers(applyMiddleware(thunk)));

export default store;
