import React, {Component, Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MatchView from "./MatchView";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import * as actions from "../redux/actions";
import MatchEdit from "./MatchEdit";
import StatusIcon from "./StatusIcon";

const useStyles = theme => ({
    appBarPopup: {
        position: 'relative',
        background: 'linear-gradient(to left, rgba(0,0,0,0) 60%,\n' +
            '              #330066);',
        backgroundColor: '#101010',
    },
    toolbar: {
        justifyContent: "center",
        alignItems: "center",
        display: 'flex'
    },
    title: {
        textAlign: 'center'
    },
    closeButton: {
    },
    options: {
    },
    grow: {
        flexGrow: 1,
    },

});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class MatchViewPopup extends Component {

    render() {

        const {account, matches, classes, onCloseMatchViewPopup, onOpenMatchViewEditPopup, onConfirmMatch } = this.props;

        const handleClose = (e) => {
            e.preventDefault();
            onCloseMatchViewPopup();
        };

        //Save logic
        const handleEdit = (e) => {
            e.preventDefault();
            onOpenMatchViewEditPopup();
        };

        //Save logic
        const handleConfirm = (e) => {
            e.preventDefault();
            onConfirmMatch(matches.matchView.match_obj.id);
        };

        return (
            <Dialog fullScreen open={matches.matchView.popupOpen} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar style={{background: 'linear-gradient(to left, rgba(0,0,0,0) 60%, #330066)',
                                backgroundColor: '#101010',
                                position: 'relative'
                }}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton className={classes.closeButton} edge="start" color="inherit" onClick={handleClose}
                                    aria-label="close">
                            <CloseIcon/>
                        </IconButton>
                        <div style={{position: 'absolute', overflowX:'auto'}}>
                            <Typography variant="subtitle2" className={classes.title}>
                                Match #{matches.matchView.match_id}<br /></Typography>
                            <Typography variant="subtitle1" className={classes.title}>{matches.matchView.match_obj ? (matches.matchView.match_obj.games_a > matches.matchView.match_obj.games_b ? matches.matchView.match_obj.participant_a_name + ' ' + (matches.matchView.match_obj.games_a  || '0')  : matches.matchView.match_obj.participant_b_name + ' ' + (matches.matchView.match_obj.games_b  || '0')) + ' ' : ''}
                                vs
                                {matches.matchView.match_obj ? ' ' + (matches.matchView.match_obj.games_a > matches.matchView.match_obj.games_b ? (matches.matchView.match_obj.games_b  || '0') + ' ' + matches.matchView.match_obj.participant_b_name  : (matches.matchView.match_obj.games_a  || '0') + ' ' + matches.matchView.match_obj.participant_a_name ) : ''}
                            </Typography>
                        </div>
                        <div className={classes.grow}/>
                        <StatusIcon status={matches.matchView.match_obj.report_type} />{matches.matchView.match_obj.confirmed && (<StatusIcon status={'locked'}/>)}
                        <div>
                            {matches.matchView.canEdit && (
                                <Fragment>
                                    {!!matches.matchView.match_obj.winner && (
                                        <>
                                            <Button disabled={matches.matchView.match_obj.confirmed || !account.is_tournament_admin} className={classes.options} color="inherit" onClick={handleConfirm}>
                                                CONFIRM
                                            </Button>
                                        </>
                                        )}
                                    <Button className={classes.options} color="inherit" onClick={handleEdit}>
                                        EDIT
                                    </Button>

                                </Fragment>
                            )}
                        </div>
                    </Toolbar>
                </AppBar>
                <MatchView loaded={matches.matchView.loaded} matchView={matches.matchView}/>
                <MatchEdit />
            </Dialog>
        );
    }
}

const styled = withStyles(useStyles)(MatchViewPopup);
export default connect(state => { return {matches: state.matches, account: state.account} }, {...actions})(styled);