import React, {useContext} from 'react';
import {createMuiTheme, CssBaseline} from "@material-ui/core";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { Provider } from 'react-redux';
import store from './redux/store';
import {red} from "@material-ui/core/colors";
import ErrorDialog from "./components/ErrorDialog";
import NewAppBar from "./components/NewAppBar";
import {LayoutContext} from "./components/Layout/Root";


const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Roboto',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
    },
    palette: {
        type: 'dark',
        primary: {
            main: "#330066",
            light: "#999999"
        },
        secondary: {
            main: "#993344",
        },
        error: red,
        // Used by `getContrastText()` to maximize the contrast between the background and
        // the text.
        //contrastThreshold: 0.1,
        // Used to shift a color's luminance by approximately
        // two indexes within its tonal palette.
        // E.g., shift from Red 500 to Red 300 or Red 700.
        //tonalOffset: 2,

    },
    overrides:{
        MuiTextField: {
            root: {

            }
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid rgba(81, 81, 81, 0.2)',
                padding: '3px 3px 1.5px 1.5px',
                "&:last-child": {
                    paddingRight: '5px',
                },
                "&:first-child": {
                    paddingLeft: '5px',
                }
            },
        },
        MuiTableHead:{
            root:{

            },
        }
    }

});

function App() {
    // eslint-disable-next-line no-restricted-globals
    return (
        <CssBaseline>
            <ThemeProvider theme={theme}>
                <Provider store={store}>
                    <NewAppBar />
                </Provider>
            </ThemeProvider>
        </CssBaseline>
    );
}

export default App;
