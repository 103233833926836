import React from 'react';
import PropTypes from 'prop-types';
import StatusIcon from "./StatusIcon";
import {Tooltip} from "@material-ui/core";
import {Add, Edit, FileCopy, FileCopyOutlined, Share, Visibility} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import styled from 'styled-components';



const HoveredFileCopyOutlined = styled(FileCopyOutlined)`
    color: rgba(255,255,255,0.2);
    :hover {
        color: #fff;
    }
`;

const HoveredEdit = styled(Edit)`
    color: rgba(255,255,255,0.2);
    :hover {
        color: #fff;
    }
`;

const HoveredAdd = styled(Add)`
    color: rgba(255,255,255,0.2);
    :hover {
        color: #fff;
    }
`;

const HoveredTableRow = styled(TableRow)`
    background-color: #424242;
    :hover {
        background-color: rgba(51,0,102,0.2);
    }
`;


const ScoreboardLine = ({account, line, actions}) => {

    const {onEditReport, onViewReport, onConfirmReport, onGetMatchMaps} = actions;

    let teams = [];

    if (account.profile && account.profile.player && account.profile.player.players) {
        teams = account.profile.player.players.map(part => part)
    }


    const handleViewReport = function(e) {
        e.preventDefault();
        //Setting view initial state
        onViewReport({obj: line, id: line.id, canEdit: (  //Either captain of one of the teams when not confirmed
                    (
                        !line.confirmed && account.is_captain &&
                        (teams.includes(line.participant_a.id) ||
                            teams.includes(line.participant_b.id) )
                    ) || //Or tournament admin
                    account.is_tournament_admin
                ), is_tournament_admin: account.is_tournament_admin, is_captain: account.is_captain});
        //Getting view data
        onGetMatchMaps(line.id);
    };
    const handleEditReport = function(e) {
        e.preventDefault();
        //Setting view initial state
        onEditReport({obj: line, id: line.id, canEdit: (  //Either captain of one of the teams when not confirmed
                    (
                        !line.confirmed && account.is_captain &&
                        (teams.includes(line.participant_a.id) ||
                            teams.includes(line.participant_b.id) )
                    ) || //Or tournament admin
                    account.is_tournament_admin
                ), is_tournament_admin: account.is_tournament_admin, is_captain: account.is_captain});
        //Getting view data
        onGetMatchMaps(line.id);
    };
    const handleCreateReport = function(e) {
        e.preventDefault();
        //Setting view initial state
        onEditReport({obj: line, id: line.id, canEdit: (  //Either captain of one of the teams when not confirmed
                    (
                        !line.confirmed && account.is_captain &&
                        (teams.includes(line.participant_a.id) ||
                            teams.includes(line.participant_b.id) )
                    ) || //Or tournament admin
                    account.is_tournament_admin
                ), is_tournament_admin: account.is_tournament_admin, is_captain: account.is_captain});
        //Getting view data
        onGetMatchMaps(line.id);
    };

    let tt = (<div align="center"  style={{display: 'flex', flexFlow: 'line'}}>
        <div>{moment(line.confirmation_date).format('DD/MM/YYYY - hh:mm')}</div>
        {/*<div>{moment(line.confirmation_date).format(' hh:mm')}</div>*/}
    </div>);

    return (
        <HoveredTableRow key={line.id}>
            <TableCell align="center" style={{color: 'rgba(255,255,255,0.2)', display: line.confirmed ? 'flex' : 'block', alignItems:'center'}}>
                <div>
                    <StatusIcon status={line.report_type} />{line.confirmed && (<StatusIcon msg={tt} status={'locked'}/>)}
                </div>
                {/*{line.confirmation_date && (*/}
                {/*    <div align="center" style={{display: 'flex', flexFlow:'column', fontSize: '0.7rem'}}>*/}
                {/*        <div>{moment(line.confirmation_date).format('DD/MM/YYYY')}</div>*/}
                {/*        <div>{moment(line.confirmation_date).format('hh:mm:ss')}</div>*/}
                {/*    </div>*/}
                {/*)}*/}
            </TableCell>
            <TableCell align="right" style={{ textTransform: "uppercase", fontWeight: line.games_a > line.games_b ? "bold" : "unset"}}>{line.participant_a_name}</TableCell>
            <TableCell align="right" style={{fontWeight: line.games_a > line.games_b ? "bold" : "unset"}}>
                {line.report_type === 'waiting' ? '-' : line.games_a}
            </TableCell>
            <TableCell align="center">
                {line.report_type !== 'waiting' && (
                <Tooltip title="View Report">
                    <IconButton onClick={handleViewReport} id={"openReport"+line.id} size="small">
                        <Visibility color="primary">
                        </Visibility>
                    </IconButton>
                </Tooltip>)}
                {line.report_type === 'waiting' && (
                    'vs'
                )}
            </TableCell>
            <TableCell align="left" style={{fontWeight: line.games_b > line.games_a ? "bold" : "unset"}}>
                {line.report_type === 'waiting' ? '-' : line.games_b}
            </TableCell>
            <TableCell align="left" style={{ textTransform: "uppercase", fontWeight: line.games_b > line.games_a ? "bold" : "unset"}}>{line.participant_b_name}</TableCell>
            <TableCell align="right">
                {(  //Either captain of one of the teams and not confirmed
                    (
                    !line.confirmed && account.is_captain &&
                    (teams.includes(line.participant_a.id) ||
                        teams.includes(line.participant_b.id) )
                    ) || //Or tournament admin
                    account.is_tournament_admin
                )
                    //And must be reported already
                && line.report_type !== 'waiting' && (
                    <Tooltip title="Edit Report">
                        <IconButton style={{color: 'rgba(255,255,255,0.2)'}} onClick={handleEditReport} size="small">
                            <HoveredEdit/>
                        </IconButton>
                    </Tooltip>)}
                {(  //Either captain of one of the teams and not confirmed
                    (
                        !line.confirmed && account.is_captain &&
                        (teams.includes(line.participant_a.id) ||
                            teams.includes(line.participant_b.id) )
                    ) || //Or tournament admin
                    account.is_tournament_admin
                )
                //And must be not reported yet
                && line.report_type === 'waiting' && (
                    <Tooltip title="Add Report">
                        <IconButton style={{color: 'rgba(255,255,255,0.2)'}} onClick={handleCreateReport} size="small">
                            <HoveredAdd/>
                        </IconButton>
                    </Tooltip>)}
                {line.report_type !== 'waiting' && (
                    <Tooltip title="Copy Report Link to Clipboard">
                        <IconButton onClick={() => {
                            // Create new element
                            let el = document.createElement('textarea');
                            // Set value (string to be copied)
                            el.value = "https://getquad.quake.world/#report" + line.id.toString();
                            // Set non-editable to avoid focus and move outside of view
                            el.setAttribute('readonly', '');
                            el.style = {position: 'absolute', left: '-9999px'};
                            document.body.appendChild(el);
                            // Select text inside element
                            el.select();
                            // Copy text to clipboard
                            document.execCommand('copy');
                            // Remove temporary element
                            document.body.removeChild(el);
                        }} id={"openReport"+line.id} size="small">
                            <HoveredFileCopyOutlined />
                        </IconButton>
                    </Tooltip>)}
            </TableCell>
        </HoveredTableRow>
    );
};
export default ScoreboardLine;
