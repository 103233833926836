import React, {Component, Fragment, useEffect} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, Typography} from "@material-ui/core";
import {Warning} from "@material-ui/icons";
import ScoreboardLine from "./ScoreboardLine";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import LinearProgress from "@material-ui/core/LinearProgress";
import {styled, withStyles} from "@material-ui/styles";
import styledc from 'styled-components';
import {connect} from "react-redux";
import * as actions from "../redux/actions";
import {onGettingMatches} from "../redux/actions";
import MatchViewPopup from "./MatchViewPopup";

const LoadingLine = styled(LinearProgress)({
    marginTop: "10px",
    marginBottom: "10px",
    width: "100%"

});

const HoveredTableRow = styledc(TableRow)`
    background-color: #424242;
    :hover {
        background-color: rgba(51,0,102,0.2);
    }
`;

const styles = theme => ({
    paper: {
        //width: '65%',
        overflowX: 'auto',
        marginLeft: '5px',
        marginRight: '5px',
        marginBottom: '10px'
    },
    table: {
        //minWidth: 650,
        minWidth: 'max-content'

    },
    head: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    }
});


class Participants extends Component {

    handleResize = (e) => {
        this.setState({windowWidth: (window.innerWidth > 0) ? window.innerWidth : window.screen.width});
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        window.removeEventListener('orientationchange', this.handleResize);
    };

    componentDidMount() {
        this.props.onGettingParticipants();
        this.props.onGetBigParticipants();
        this.setState({windowWidth: (window.innerWidth > 0) ? window.innerWidth : window.screen.width});
        window.addEventListener('resize', this.handleResize);
        window.addEventListener('orientationchange', this.handleResize);
    }

    render() {

        const {participants, classes, matches} = this.props;

        let last_tour = '';
        let tours = [];
        let orderedParticipants = [];
        if (participants.loaded && participants.objects.length > 0) {
            orderedParticipants = participants.objects.slice().sort((a, b) => {
                if (a.tournament.name < b.tournament.name) {
                    return -1;
                }
                if (a.tournament.name > b.tournament.name) {
                    return 1;
                }
                return 0;
            });
            orderedParticipants.forEach((part) => {
                if (part.tournament.name !== last_tour) {
                    last_tour = part.tournament.name;
                    tours.push(part.tournament.name);
                }
            })
        }

        if (!participants.loaded || !matches.stages_loaded) {
            return (
                <Paper className={classes.paper} style={{maxWidth: this.state ? this.state.windowWidth : "unset"}}>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} align="center" colSpan={8}>-</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center">Team</TableCell>
                                <TableCell align="center">Played</TableCell>
                                <TableCell align="center">Match Wins</TableCell>
                                <TableCell align="center">Match Losses</TableCell>
                                <TableCell align="center">Map Wins</TableCell>
                                <TableCell align="center">Map Losses</TableCell>
                                <TableCell align="center">Net Maps</TableCell>
                                <TableCell align="center">Net Frags</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" style={{alignContent: "center"}} colSpan={8}><LoadingLine/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" style={{alignContent: "center"}} colSpan={8}><LoadingLine/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" style={{alignContent: "center"}} colSpan={8}><LoadingLine/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" style={{alignContent: "center"}} colSpan={8}><LoadingLine/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" style={{alignContent: "center"}} colSpan={8}><LoadingLine/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell align="center" style={{alignContent: "center"}} colSpan={8}><LoadingLine/></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>)
        } else {
            return (
                <Fragment>
                    {tours.map((tour, i) => (
                            <Paper key={i.toString()} className={classes.paper}
                                   style={{maxWidth: this.state ? this.state.windowWidth : "unset"}}>

                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.head} align="center"
                                                       colSpan={8}>{tour}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center" style={{fontWeight: "bold"}}>Team</TableCell>
                                            <TableCell align="center">Played</TableCell>
                                            <TableCell align="center" style={{fontWeight: "bold"}}>Match Wins</TableCell>
                                            <TableCell align="center">Match Losses</TableCell>
                                            <TableCell align="center">Map Wins</TableCell>
                                            <TableCell align="center">Map Losses</TableCell>
                                            <TableCell align="center" style={{fontWeight: "bold"}}>Net Maps</TableCell>
                                            <TableCell align="center">Net Frags</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {participants.objects.length > 0 && participants.loaded && orderedParticipants.length > 0 && orderedParticipants.map((part, index) => {
                                            if (part.tournament.name === tour) {
                                                return (
                                                    <HoveredTableRow key={index.toString()}>
                                                        <TableCell align="right" style={{
                                                            fontWeight: "bold",
                                                            textTransform: "uppercase"
                                                        }}>{part.team.name || 0}</TableCell>
                                                        <TableCell align="center">{part.matches_played || 0}</TableCell>
                                                        <TableCell align="center"
                                                                   style={{fontWeight: "bold"}}>{part.match_wins || 0}</TableCell>
                                                        <TableCell align="center">{part.match_losses || 0}</TableCell>
                                                        <TableCell align="center">{part.matchmap_wins || 0}</TableCell>
                                                        <TableCell align="center">{part.matchmap_losses || 0}</TableCell>
                                                        <TableCell style={{fontWeight: "bold"}}
                                                                   align="center">{part.matchmap_net || 0}</TableCell>
                                                        <TableCell align="center">{part.scores_net || 0}</TableCell>
                                                    </HoveredTableRow>
                                                )
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </Paper>
                        )
                    )}
                </Fragment>
            );
        }
    }
}

const stylee = withStyles(styles)(Participants);
export default connect(state => {
    return {participants: state.participants, matches: state.matches}
}, {...actions})(stylee);
