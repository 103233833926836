import React, { Fragment, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import * as mvd from '../mvdProtocol';
import * as moment from 'moment';
import NewsPosts from './NewsPosts/Timeline';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import styledc from 'styled-components';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import Tooltip from '@material-ui/core/Tooltip';

const HoveredTableRow = styledc(TableRow)`
    textDecoration: solid;
    background: rgba(255, 255, 255, 0.05);
    :hover {
        background: rgba(255, 255, 255, 0.1);
    }
`;

const useStyles = makeStyles({
  paperRoot: {
    height: '100%',
    alignItems: 'center',
  },

  tabs: {
    flexGrow: 1,
    backgroundColor: '#4f4f4f',
    top: 'auto',
    bottom: 0,
    position: 'fixed',
    alignSelf: 'center',
  },
  progress: {
    flexGrow: 1,
    position: 'fixed',
    alignSelf: 'center',
  },
  report: {},
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  split_0_0: {
    backgroundColor: 'rgba(123, 123, 123, 255)',
  },

  split_0_1: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_0_2: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_0_3: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_0_4: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_0_5: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_0_6: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_0_7: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_0_8: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_0_9: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_0_10: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_0_11: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_0_12: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_0_13: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_1_0: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_1_1: {
    backgroundColor: 'rgba(83, 59, 27, 255)',
  },
  split_1_2: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_1_3: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_1_4: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_1_5: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_1_6: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_1_7: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_1_8: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_1_9: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_1_10: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_1_11: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_1_12: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_1_13: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_2_0: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_2_1: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_2_2: {
    backgroundColor: 'rgba(79, 79, 115, 255)',
  },
  split_2_3: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_2_4: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_2_5: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_2_6: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_2_7: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_2_8: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_2_9: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_2_10: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_2_11: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_2_12: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_2_13: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_3_0: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_3_1: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_3_2: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_3_3: {
    backgroundColor: 'rgba(55, 55, 7, 255)',
  },
  split_3_4: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_3_5: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_3_6: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_3_7: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_3_8: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_3_9: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_3_10: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_3_11: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_3_12: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_3_13: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_4_0: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_4_1: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_4_2: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_4_3: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_4_4: {
    backgroundColor: 'rgba(71, 0, 0, 255)',
  },
  split_4_5: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_4_6: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_4_7: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_4_8: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_4_9: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_4_10: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_4_11: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_4_12: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_4_13: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_5_0: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_5_1: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_5_2: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_5_3: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_5_4: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_5_5: {
    backgroundColor: 'rgba(95, 71, 7, 255)',
  },
  split_5_6: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_5_7: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_5_8: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_5_9: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_5_10: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_5_11: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_5_12: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_5_13: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_6_0: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_6_1: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_6_2: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_6_3: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_6_4: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_6_5: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_6_6: {
    backgroundColor: 'rgba(143, 67, 51, 255)',
  },
  split_6_7: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_6_8: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_6_9: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_6_10: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_6_11: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_6_12: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_6_13: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_7_0: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_7_1: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_7_2: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_7_3: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_7_4: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_7_5: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_7_6: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_7_7: {
    backgroundColor: 'rgba(127, 83, 63, 255)',
  },
  split_7_8: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_7_9: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_7_10: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_7_11: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_7_12: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_7_13: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_8_0: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_8_1: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_8_2: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_8_3: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_8_4: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_8_5: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_8_6: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_8_7: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_8_8: {
    backgroundColor: 'rgba(87, 55, 67, 255)',
  },
  split_8_9: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_8_10: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_8_11: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_8_12: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_8_13: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_9_0: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_9_1: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_9_2: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_9_3: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_9_4: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_9_5: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_9_6: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_9_7: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_9_8: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_9_9: {
    backgroundColor: 'rgba(95, 51, 63, 255)',
  },
  split_9_10: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_9_11: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_9_12: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_9_13: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_10_0: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_10_1: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_10_2: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_10_3: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_10_4: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_10_5: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_10_6: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_10_7: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_10_8: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_10_9: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_10_10: {
    backgroundColor: 'rgba(107, 87, 71, 255)',
  },
  split_10_11: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_10_12: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_10_13: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_11_0: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_11_1: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_11_2: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_11_3: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_11_4: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_11_5: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_11_6: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_11_7: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_11_8: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_11_9: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_11_10: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_11_11: {
    backgroundColor: 'rgba(47, 67, 55, 255)',
  },
  split_11_12: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_11_13: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_12_0: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_12_1: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_12_2: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_12_3: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_12_4: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_12_5: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_12_6: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_12_7: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_12_8: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_12_9: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_12_10: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_12_11: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_12_12: {
    backgroundColor: 'rgba(123, 99, 7, 255)',
  },
  split_12_13: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  split_13_0: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  split_13_1: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  split_13_2: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  split_13_3: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  split_13_4: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  split_13_5: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  split_13_6: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  split_13_7: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  split_13_8: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  split_13_9: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  split_13_10: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  split_13_11: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  split_13_12: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  split_13_13: {
    backgroundColor: 'rgba(47, 47, 127, 255)',
  },

  lg_split_0_1: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_0_2: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_0_3: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_0_4: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_0_5: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_0_6: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_0_7: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_0_8: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_0_9: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_0_10: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_0_11: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_0_12: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_0_13: {
    background:
      'linear-gradient(to bottom, rgba(123, 123, 123, 255) 0%, rgba(123, 123, 123, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_1_0: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_1_1: {
    backgroundColor: 'rgba(83, 59, 27, 255)',
  },
  lg_split_1_2: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_1_3: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_1_4: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_1_5: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_1_6: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_1_7: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_1_8: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_1_9: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_1_10: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_1_11: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_1_12: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_1_13: {
    background:
      'linear-gradient(to bottom, rgba(83, 59, 27, 255) 0%, rgba(83, 59, 27, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_2_0: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_2_1: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_2_2: {
    backgroundColor: 'rgba(79, 79, 115, 255)',
  },
  lg_split_2_3: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_2_4: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_2_5: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_2_6: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_2_7: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_2_8: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_2_9: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_2_10: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_2_11: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_2_12: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_2_13: {
    background:
      'linear-gradient(to bottom, rgba(79, 79, 115, 255) 0%, rgba(79, 79, 115, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_3_0: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_3_1: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_3_2: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_3_3: {
    backgroundColor: 'rgba(55, 55, 7, 255)',
  },
  lg_split_3_4: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_3_5: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_3_6: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_3_7: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_3_8: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_3_9: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_3_10: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_3_11: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_3_12: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_3_13: {
    background:
      'linear-gradient(to bottom, rgba(55, 55, 7, 255) 0%, rgba(55, 55, 7, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_4_0: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_4_1: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_4_2: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_4_3: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_4_4: {
    backgroundColor: 'rgba(71, 0, 0, 255)',
  },
  lg_split_4_5: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_4_6: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_4_7: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_4_8: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_4_9: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_4_10: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_4_11: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_4_12: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_4_13: {
    background:
      'linear-gradient(to bottom, rgba(71, 0, 0, 255) 0%, rgba(71, 0, 0, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_5_0: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_5_1: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_5_2: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_5_3: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_5_4: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_5_5: {
    backgroundColor: 'rgba(95, 71, 7, 255)',
  },
  lg_split_5_6: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_5_7: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_5_8: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_5_9: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_5_10: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_5_11: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_5_12: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_5_13: {
    background:
      'linear-gradient(to bottom, rgba(95, 71, 7, 255) 0%, rgba(95, 71, 7, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_6_0: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_6_1: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_6_2: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_6_3: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_6_4: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_6_5: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_6_6: {
    backgroundColor: 'rgba(143, 67, 51, 255)',
  },
  lg_split_6_7: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_6_8: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_6_9: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_6_10: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_6_11: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_6_12: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_6_13: {
    background:
      'linear-gradient(to bottom, rgba(143, 67, 51, 255) 0%, rgba(143, 67, 51, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_7_0: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_7_1: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_7_2: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_7_3: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_7_4: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_7_5: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_7_6: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_7_7: {
    backgroundColor: 'rgba(127, 83, 63, 255)',
  },
  lg_split_7_8: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_7_9: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_7_10: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_7_11: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_7_12: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_7_13: {
    background:
      'linear-gradient(to bottom, rgba(127, 83, 63, 255) 0%, rgba(127, 83, 63, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_8_0: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_8_1: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_8_2: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_8_3: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_8_4: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_8_5: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_8_6: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_8_7: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_8_8: {
    backgroundColor: 'rgba(87, 55, 67, 255)',
  },
  lg_split_8_9: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_8_10: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_8_11: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_8_12: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_8_13: {
    background:
      'linear-gradient(to bottom, rgba(87, 55, 67, 255) 0%, rgba(87, 55, 67, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_9_0: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_9_1: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_9_2: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_9_3: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_9_4: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_9_5: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_9_6: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_9_7: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_9_8: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_9_9: {
    backgroundColor: 'rgba(95, 51, 63, 255)',
  },
  lg_split_9_10: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_9_11: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_9_12: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_9_13: {
    background:
      'linear-gradient(to bottom, rgba(95, 51, 63, 255) 0%, rgba(95, 51, 63, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_10_0: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_10_1: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_10_2: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_10_3: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_10_4: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_10_5: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_10_6: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_10_7: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_10_8: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_10_9: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_10_10: {
    backgroundColor: 'rgba(107, 87, 71, 255)',
  },
  lg_split_10_11: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_10_12: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_10_13: {
    background:
      'linear-gradient(to bottom, rgba(107, 87, 71, 255) 0%, rgba(107, 87, 71, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_11_0: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_11_1: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_11_2: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_11_3: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_11_4: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_11_5: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_11_6: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_11_7: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_11_8: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_11_9: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_11_10: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_11_11: {
    backgroundColor: 'rgba(47, 67, 55, 255)',
  },
  lg_split_11_12: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_11_13: {
    background:
      'linear-gradient(to bottom, rgba(47, 67, 55, 255) 0%, rgba(47, 67, 55, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_12_0: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_12_1: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_12_2: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_12_3: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_12_4: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_12_5: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_12_6: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_12_7: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_12_8: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_12_9: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_12_10: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_12_11: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_12_12: {
    backgroundColor: 'rgba(123, 99, 7, 255)',
  },
  lg_split_12_13: {
    background:
      'linear-gradient(to bottom, rgba(123, 99, 7, 255) 0%, rgba(123, 99, 7, 255) 50%, rgba(47, 47, 127, 255) 50%, rgba(47, 47, 127, 255) 100%)',
  },
  lg_split_13_0: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(123, 123, 123, 255) 50%, rgba(123, 123, 123, 255) 100%)',
  },
  lg_split_13_1: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(83, 59, 27, 255) 50%, rgba(83, 59, 27, 255) 100%)',
  },
  lg_split_13_2: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(79, 79, 115, 255) 50%, rgba(79, 79, 115, 255) 100%)',
  },
  lg_split_13_3: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(55, 55, 7, 255) 50%, rgba(55, 55, 7, 255) 100%)',
  },
  lg_split_13_4: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(71, 0, 0, 255) 50%, rgba(71, 0, 0, 255) 100%)',
  },
  lg_split_13_5: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(95, 71, 7, 255) 50%, rgba(95, 71, 7, 255) 100%)',
  },
  lg_split_13_6: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(143, 67, 51, 255) 50%, rgba(143, 67, 51, 255) 100%)',
  },
  lg_split_13_7: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(127, 83, 63, 255) 50%, rgba(127, 83, 63, 255) 100%)',
  },
  lg_split_13_8: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(87, 55, 67, 255) 50%, rgba(87, 55, 67, 255) 100%)',
  },
  lg_split_13_9: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(95, 51, 63, 255) 50%, rgba(95, 51, 63, 255) 100%)',
  },
  lg_split_13_10: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(107, 87, 71, 255) 50%, rgba(107, 87, 71, 255) 100%)',
  },
  lg_split_13_11: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(47, 67, 55, 255) 50%, rgba(47, 67, 55, 255) 100%)',
  },
  lg_split_13_12: {
    background:
      'linear-gradient(to bottom, rgba(47, 47, 127, 255) 0%, rgba(47, 47, 127, 255) 50%, rgba(123, 99, 7, 255) 50%, rgba(123, 99, 7, 255) 100%)',
  },
  lg_split_13_13: {
    backgroundColor: 'rgba(47, 47, 127, 255)',
  },
});

function Scoreboard(props) {
  const { matchObj } = props;
  const classes = useStyles();
  if (!!props.activeMM && !!props.activeMM.mvdmapstat && !!props.activeMM.mvdmapstat.mvd_data) {
    const orderedPlayers = props.activeMM.mvdmapstat.mvd_data.players.slice().sort((a, b) => {
      if (a[mvd.d_team] === b[mvd.d_team]) {
        if (parseInt(a[mvd.d_score]) > parseInt(b[mvd.d_score])) return -1;
        if (parseInt(a[mvd.d_score]) < parseInt(b[mvd.d_score])) return 1;
      }
      if (
        a[mvd.d_team].replace(/\W/g, '').toLowerCase() ===
        (matchObj.games_a > matchObj.games_b
          ? matchObj.participant_a.team.tag.replace(/\W/g, '').toLowerCase()
          : matchObj.participant_b.team.tag.replace(/\W/g, '').toLowerCase())
      ) {
        return -1;
      } else {
        return 1;
      }
    });
    let enemyFirstIndex = orderedPlayers.findIndex((player) => player[mvd.d_team] !== orderedPlayers[0][mvd.d_team]);
    return orderedPlayers.map((player, index) => {
      if (index < enemyFirstIndex) {
        return (
          <HoveredTableRow key={index}>
            <TableCell align="right">{player[mvd.d_effi] + '%'}</TableCell>
            <TableCell align="right">{player[mvd.d_deaths]}</TableCell>
            <TableCell style={{ fontSize: '1.2rem', paddingRight: '15px' }} align="right">
              {player[mvd.d_name]}
            </TableCell>
            <TableCell
              style={{ fontSize: '1.2rem' }}
              align="center"
              className={classes['split_' + player[mvd.d_tcolor] + '_' + player[mvd.d_bcolor]]}
            >
              {player[mvd.d_score]}
            </TableCell>
            <TableCell style={{ fontSize: '1.2rem' }} align="center">
              {' x '}
            </TableCell>
            {index + enemyFirstIndex < orderedPlayers.length && (
              <Fragment>
                <TableCell
                  align="center"
                  className={
                    classes[
                      'split_' +
                        orderedPlayers[index + enemyFirstIndex][mvd.d_tcolor] +
                        '_' +
                        orderedPlayers[index + enemyFirstIndex][mvd.d_bcolor]
                    ]
                  }
                  style={{ fontSize: '1.2rem', maxWidth: '80%' }}
                >
                  {orderedPlayers[index + enemyFirstIndex][mvd.d_score]}
                </TableCell>
                <TableCell style={{ fontSize: '1.2rem', paddingLeft: '15px' }} align="left">
                  {orderedPlayers[index + enemyFirstIndex][mvd.d_name]}
                </TableCell>
                <TableCell align="left">{orderedPlayers[index + enemyFirstIndex][mvd.d_deaths]}</TableCell>
                <TableCell align="left">{orderedPlayers[index + enemyFirstIndex][mvd.d_effi] + '%'}</TableCell>
              </Fragment>
            )}
          </HoveredTableRow>
        );
      } else {
        return <Fragment key={index.toString() + 'SUAVE'} />;
      }
    });
  }
  return (
    <TableRow>
      <TableCell style={{ textAlign: 'center' }} colSpan={25}>
        No Content{' '}
        {!!props.activeMM && (!props.activeMM.mvdmapstat || !props.activeMM.mvdmapstat.mvd_data)
          ? '- Detached report mode: NO DEMO, NO STATS.'
          : ''}
      </TableCell>
    </TableRow>
  );
}

function PlayerStats(props) {
  const { matchObj } = props;
  const classes = useStyles();
  if (!!props.activeMM && !!props.activeMM.mvdmapstat && !!props.activeMM.mvdmapstat.mvd_data) {
    const orderedPlayers = props.activeMM.mvdmapstat.mvd_data.players.slice().sort((a, b) => {
      if (parseInt(a[mvd.d_score]) > parseInt(b[mvd.d_score])) return -1;
      if (parseInt(a[mvd.d_score]) < parseInt(b[mvd.d_score])) return 1;
    });
    return orderedPlayers.map((player, index) => {
      return (
        <Fragment key={index}>
          <HoveredTableRow key={index}>
            <TableCell align="right">{player[mvd.d_name]}</TableCell>
            <TableCell align="center">{player[mvd.d_score]}</TableCell>
            <TableCell align="center">{player[mvd.d_deaths]}</TableCell>
            <TableCell align="center" style={{ borderRight: '1px solid rgba(81, 81, 81, 0.2)' }}>
              {player[mvd.d_effi] + '%'}
            </TableCell>
            <TableCell align="center">{player[mvd.d_given]}</TableCell>
            <TableCell align="center">{player[mvd.d_taken]}</TableCell>
            <TableCell align="center">{player[mvd.d_todeath]}</TableCell>
            <TableCell align="center">
              {player[mvd.d_ewep] < 0 || player[mvd.d_ewep] > 999999 ? 'n/a' : player[mvd.d_ewep]}
            </TableCell>
            <TableCell align="center" style={{ borderRight: '1px solid rgba(81, 81, 81, 0.2)' }}>
              {player[mvd.d_tmdmg] < 0 || player[mvd.d_tmdmg] > 999999 ? 'n/a' : player[mvd.d_tmdmg]}
            </TableCell>
            <TableCell align="center">{moment.utc(player[mvd.d_s_time] * 1000).format('mm:ss')}</TableCell>
            <TableCell align="center">{moment.utc(player[mvd.d_l_time] * 1000).format('mm:ss')}</TableCell>
            <TableCell align="center">{moment.utc(player[mvd.d_r_time] * 1000).format('mm:ss')}</TableCell>
          </HoveredTableRow>
          {/*{index > 0 && (index + 1) < orderedPlayers.length && orderedPlayers[index+1][mvd.d_team] !== player[mvd.d_team] && (*/}
          {/*    <TableRow style={{background:'rgb(255, 255, 255, 0.1)'}}>*/}
          {/*        <TableCell align="center" colSpan={12}>{'-'}</TableCell>*/}
          {/*    </TableRow>*/}
          {/*)}*/}
        </Fragment>
      );
    });
  }
  return (
    <TableRow>
      <TableCell style={{ textAlign: 'center' }} colSpan={25}>
        No Content{' '}
        {!!props.activeMM && (!props.activeMM.mvdmapstat || !props.activeMM.mvdmapstat.mvd_data)
          ? '- Detached report mode: NO DEMO, NO STATS.'
          : ''}
      </TableCell>
    </TableRow>
  );
}

export default function CenteredTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [mmWinner, setMMWinner] = React.useState();
  const [activeMM, setActiveMM] = React.useState();
  const [inDrama, setInDrama] = React.useState(false);
  const matchView = props.matchView;
  const matchObj = matchView.match_obj;

  useEffect(() => {
    if (document.getElementById('dramaKey')) {
      if (window.location.hash.substring(1).replace(/\d+/g, '') === 'reportDrama') {
        window.location.hash = '';
        setActiveMM('');
        setMMWinner('');
        setInDrama(true);
        setValue(matchView.matchMaps.length);
      } else {
        window.location.hash = '';
      }
    }
  }, [matchView.matchMaps.length]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue < matchView.matchMaps.length) {
      setInDrama(false);
      setActiveMM(matchView.matchMaps[newValue]);
      setMMWinner(
        matchView.matchMaps[newValue].score_a > matchView.matchMaps[newValue].score_b
          ? matchView.match_obj.participant_a.team.tag.replace(/^\S+/g, '').toLowerCase()
          : matchView.match_obj.participant_b.team.tag.replace(/^\S+/g, '').toLowerCase()
      );
    } else {
      setActiveMM('');
      setMMWinner('');
      setInDrama(true);
    }
  };

  if (!matchView.loaded && !!activeMM) {
    setActiveMM('');
    setMMWinner('');
  }

  if (matchView.loaded && !activeMM && !inDrama) {
    if (matchView.matchMaps.length > 0) {
      setActiveMM(matchView.matchMaps[0]);
      setMMWinner(
        matchView.matchMaps[0].score_a > matchView.matchMaps[0].score_b
          ? matchView.match_obj.participant_a.team.tag.replace(/^\S+/g, '').toLowerCase()
          : matchView.match_obj.participant_b.team.tag.replace(/^\S+/g, '').toLowerCase()
      );
    }
  }

  return (
    <Fragment>
      {!matchView.loaded && !inDrama && (
        <Paper className={classes.root}>
          <CircularProgress className={classes.progress} />
        </Paper>
      )}
      <Fragment>
        {matchView.loaded && !!activeMM && !inDrama && (
          <Paper
            style={{
              background:
                'linear-gradient(to left, rgba(0,0,0,0) 10%,\n' +
                '              #020202), url(' +
                process.env.PUBLIC_URL +
                '/levelshots/' +
                activeMM.map +
                '.jpg) center center/cover no-repeat fixed',
            }}
            className={classes.paperRoot}
          >
            <div style={{ width: '100%', overflowX: 'auto' }}>
              <Table style={{ marginTop: '5px' }}>
                <TableBody>
                  {/*<TableRow>*/}
                  {/*    <TableCell style={{fontSize: '3rem', color: '#CCCCCC', borderBottom:'unset'}} colSpan={3} align="center" >{matchView.match_obj ? (matchView.match_obj.games_a > matchView.match_obj.games_b ? matchView.match_obj.participant_a_name + ' ' + (matchView.match_obj.games_a  || '0')  : matchView.match_obj.participant_b_name + ' ' + (matchView.match_obj.games_b  || '0')) + ' ' : ''}*/}
                  {/*        vs*/}
                  {/*        {matchView.match_obj ? ' ' + (matchView.match_obj.games_a > matchView.match_obj.games_b ? (matchView.match_obj.games_b  || '0') + ' ' + matchView.match_obj.participant_b_name  : (matchView.match_obj.games_a  || '0') + ' ' + matchView.match_obj.participant_a_name ) : ''}</TableCell>*/}
                  {/*</TableRow>*/}
                  <TableRow>
                    <TableCell
                      style={{ fontSize: '3rem', color: '#CCCCCC', borderBottom: 'unset' }}
                      colSpan={3}
                      align="center"
                    >
                      {activeMM.map}
                      <Link
                        href={
                          activeMM.mvdmapstat.mvd
                            ? activeMM.mvdmapstat.mvd.replace(':8000', '').replace('http', 'https')
                            : undefined
                        }
                        color="inherit"
                        className={classes.link}
                      >
                        <Tooltip title="Download MVD">
                          <GetApp fontSize="small" />
                        </Tooltip>
                      </Link>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontSize: '2rem', color: '#CCCCCC', borderBottom: 'unset' }} align="center">
                      <span style={{ color: 'rgb(220,130,10)' }}>{'['}</span>
                      <span>
                        {matchObj.games_a > matchObj.games_b
                          ? matchObj.participant_a.team.tag
                          : matchObj.participant_b.team.tag}
                      </span>
                      <span style={{ color: 'rgb(220,130,10)' }}>{'] '}</span>
                      <span>
                        {matchObj.games_a > matchObj.games_b
                          ? activeMM.score_a + ' x ' + activeMM.score_b
                          : activeMM.score_b + ' x ' + activeMM.score_a}
                      </span>
                      <span style={{ color: 'rgb(220,130,10)' }}>{' ['}</span>
                      <span>
                        {matchObj.games_a > matchObj.games_b
                          ? matchObj.participant_b.team.tag
                          : matchObj.participant_a.team.tag}
                      </span>
                      <span style={{ color: 'rgb(220,130,10)' }}>{'] '}</span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>

            <div align="center" style={{ width: '100%', overflowX: 'auto' }}>
              <Table
                align="center"
                style={{
                  alignSelf: 'center',
                  maxWidth: '700px',
                  marginLeft: '10px',
                  marginRight: '10px',
                  textShadow: '1px -1px 0 black, -1px -1px 0 black, 1px 1px 0 black, -1px 1px 0 black',
                }}
              >
                <TableHead>
                  <TableRow style={{ textDecoration: 'solid', background: 'rgb(255, 255, 255, 0.2)' }}>
                    <TableCell style={{ color: 'rgb(200,80,20)' }} align="center" colSpan={9}>
                      FINAL SCORES
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ textDecoration: 'solid', background: 'rgb(255, 255, 255, 0.1)' }}>
                    <TableCell align="right" style={{ width: '2.5%' }}>
                      effi
                    </TableCell>
                    <TableCell align="right" style={{ width: '5%' }}>
                      deaths
                    </TableCell>
                    <TableCell align="right" style={{ width: '25%' }}></TableCell>
                    <TableCell align="right" style={{ width: '10%' }}></TableCell>
                    <TableCell align="center" style={{ width: '5%' }}>
                      {' '}
                    </TableCell>
                    <TableCell align="left" style={{ width: '10%' }}></TableCell>
                    <TableCell align="left" style={{ width: '25%' }}></TableCell>
                    <TableCell align="left" style={{ width: '5%' }}>
                      deaths
                    </TableCell>
                    <TableCell align="left" style={{ width: '2.5%' }}>
                      effi
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <Scoreboard matchObj={matchObj} activeMM={activeMM} mmWinner={mmWinner} />
                </TableBody>
              </Table>
            </div>

            <div style={{ width: '100%', overflowX: 'auto', paddingLeft: '1px', paddingRight: '1px' }}>
              <Table
                style={{
                  alignSelf: 'center',
                  minWidth: '1040',
                  maxWidth: '1040',
                  marginTop: '30px',
                  marginBottom: '60px',
                  textShadow: '1px -1px 0 black, -1px -1px 0 black, 1px 1px 0 black, -1px 1px 0 black',
                }}
              >
                <TableHead>
                  <TableRow style={{ textDecoration: 'solid', background: 'rgb(255, 255, 255, 0.2)' }}>
                    <TableCell style={{ color: 'rgb(200,80,20)' }} align="center" colSpan={12}>
                      PLAYER STATS
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ textDecoration: 'solid', background: 'rgb(255, 255, 255, 0.1)' }}>
                    <TableCell
                      style={{ color: 'rgb(200,80,20)', borderRight: '1px solid rgba(81, 81, 81, 0.2)' }}
                      align="center"
                      colSpan={4}
                    >
                      scores
                    </TableCell>
                    <TableCell
                      style={{ color: 'rgb(200,80,20)', borderRight: '1px solid rgba(81, 81, 81, 0.2)' }}
                      align="center"
                      colSpan={5}
                    >
                      damage
                    </TableCell>
                    <TableCell
                      style={{ color: 'rgb(200,80,20)', borderRight: '1px solid rgba(81, 81, 81, 0.2)' }}
                      align="center"
                      colSpan={3}
                    >
                      weapon time
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ textDecoration: 'solid', background: 'rgb(255, 255, 255, 0.1)' }}>
                    <TableCell align="center">name</TableCell>
                    <TableCell align="center">scores</TableCell>
                    <TableCell align="center">deaths</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(81, 81, 81, 0.2)' }}>
                      effi
                    </TableCell>
                    <TableCell align="center">given</TableCell>
                    <TableCell align="center">taken</TableCell>
                    <TableCell align="center">toDie</TableCell>
                    <TableCell align="center">eWep</TableCell>
                    <TableCell align="center" style={{ borderRight: '1px solid rgba(81, 81, 81, 0.2)' }}>
                      team
                    </TableCell>
                    <TableCell align="center">sg</TableCell>
                    <TableCell align="center">lg</TableCell>
                    <TableCell align="center">rl</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <PlayerStats matchObj={matchObj} activeMM={activeMM} mmWinner={mmWinner} />
                </TableBody>
              </Table>
            </div>
          </Paper>
        )}
        {inDrama && (
          <div style={{ marginLeft: '30%', marginRight: '30%', marginTop: 20 }} >
            <NewsPosts />
          </div>
        )}
        <Paper className={classes.tabs}>
          <Tabs value={value} onChange={handleChange} indicatorColor="secondary" centered>
            {matchView.matchMaps.map((mm, index) => (
              <Tab key={index} label={mm.map} />
            ))}
            <Tab key={'drama'} label={'Drama'} id="dramaKey" />
          </Tabs>
        </Paper>
      </Fragment>
    </Fragment>
  );
}
