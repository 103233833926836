import React, { Component, Fragment } from 'react';
import { Warning } from '@material-ui/icons';
import ScoreboardLine from './ScoreboardLine';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import LinearProgress from '@material-ui/core/LinearProgress';
import { styled, withStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import MatchViewPopup from './MatchViewPopup';
import moment from 'moment';

const API_HOST_PORT = "getquad.quake.world";
const HTTP_STRING = "https://";

const LoadingLine = styled(LinearProgress)({
  marginTop: '10px',
  marginBottom: '10px',
  width: '700px',
});

const styles = (theme) => ({
  paper: {
    minWidth: '75%',
    //overflowX: 'hidden',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '10px',
  },
  table: {
    //width: "max-content",
    minWidth: 'max-content',
    height: 'max-content',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
});

class Brackets extends Component {
  constructor(props) {
    super(props);
    this.state = { svg: '' };
  }
  async getBrackets(matches, query) {
    let svg_url = '';
    if (matches.objects.length > 0 && matches.loaded) {
      matches.objects.forEach((tour) => {
        tour.stages.forEach((stage) => {
          if (stage.id.toString() === query.toString()) {
            svg_url = stage.svg;
          }
        });
      });
    }
    let response = await fetch(HTTP_STRING + API_HOST_PORT + '/api/brackets/?url=' + svg_url);
    let text = await response.text();
    return text
      .replace(/(<\/defs>)(.*?<\/svg>)/s, '$1')
      .replace(/^.*class="title".*$/gm, '')
      .replace(/#f3f3f3/g, '#424242')
      .replace(/#F68800/g, '#330066')
      .replace(/#d3d3d3/g, 'rgba(255, 255, 255, 0.2)')
      .replace(/#eee;/g, 'rgba(255, 255, 255, 0.05);')
      .replace(/#eeeeee/g, 'rgba(255, 255, 255, 0.05)')
      .replace(/^.*font-famil.*$/gm, '')
      .replace(/^.*fill.*222.*$/gm, 'fill: #fff; text-transform: uppercase;')
      .replace(/^.*fill.*111.*$/gm, 'fill: #fff; text-transform: uppercase;');
  }
  async componentDidUpdate(prevProps) {
    if (prevProps.query !== this.props.query) {
      this.setState({...this.state, svg: ''})
      let blah = await this.getBrackets(this.props.matches, this.props.query);
      this.setState({ ...this.state, svg: blah });
    }
  }
  async componentDidMount() {
      let blah = await this.getBrackets(this.props.matches, this.props.query);
    this.setState({ ...this.state, svg: blah });
  }

  render() {
    return this.state.svg ? (
        <div
          style={{ marginTop: '-60px', marginBottom: '-60px' }}
          dangerouslySetInnerHTML={{ __html: this.state.svg }}
        />
    ) : (
      <LoadingLine />
    );
  }
}

class StageGames extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth > 0 ? window.innerWidth : window.screen.width });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);
  }

  async componentDidMount() {
    this.props.onGettingMatches();
    this.props.onGetMatches(this.props.payload);
    this.setState({ windowWidth: window.innerWidth > 0 ? window.innerWidth : window.screen.width });
    this.setState({ ...this.state, payload: this.props.menu.activeContent.payload || this.props.query });
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
  }

  async componentDidUpdate() {
    if (window.location.hash && window.location.hash.substring(1).replace(/^\D+/g, '')) {
      const matchId = window.location.hash.substring(1).replace(/^\D+/g, '');
      if (document.getElementById('openReport' + matchId)) {
        document.getElementById('openReport' + matchId).click();
      }
    }
  }

  render() {
    const retSort = (objects) => {
      let ret = [];
      objects.forEach((tour) =>
        tour.stages
          ? tour.stages.forEach((stage) =>
              stage.matches ? stage.matches.forEach((match) => (match ? ret.push(match) : null)) : null
            )
          : null
      );
      if (ret.length > 0) {
        ret.sort((a, b) => {
          return new Date(b.confirmation_date) - new Date(a.confirmation_date);
        });
        return ret;
      } else {
        return [];
      }
    };

    const { matches, classes, menu, account, onEditReport, onViewReport, onConfirmReport, onGetMatchMaps } = this.props;
    const query = menu.activeContent.payload || this.props.query;
    let has_last_games = false;
    let stage_name = '';
    let stage_type = '';
    let challonge_url = '';
    let svg = '';
    if (query.toString() === '') {
      stage_name = 'LATEST GAMES';
    }
    if (matches.objects.length > 0 && matches.loaded) {
      matches.objects.forEach((tour) => {
        tour.stages.forEach((stage) => {
          if (stage.id.toString() === query.toString()) {
            stage_name = stage.name;
            stage_type = stage.stage_type;
            challonge_url = stage.challonge_url;
            svg = stage.svg;
          }
        });
      });
    }

    return (
      <Fragment>
        <Paper className={classes.paper} style={{ maxWidth: this.state ? this.state.windowWidth : 'unset' }}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.head} align="center" colSpan={7}>
                  {stage_name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right">Team A</TableCell>
                <TableCell align="center" colSpan={3}>
                  vs
                </TableCell>
                <TableCell align="left">Team B</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            {!matches.loaded && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <LoadingLine />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <LoadingLine />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <LoadingLine />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <LoadingLine />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <LoadingLine />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <LoadingLine />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {matches.objects.length > 0 && matches.loaded && (
              <Fragment>
                {query.toString() !== '' &&
                  matches.objects.map((tour, key) => (
                    <Fragment key={key.toString()}>
                      {tour.stages.map((stage) => (
                        <Fragment key={stage.id.toString()}>
                          {stage.matches.length === 0 && matches.loaded && stage.id.toString() === query.toString() && (
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan={7}>
                                  <br />
                                  <Warning />
                                  <br />
                                  {'No games here (yet)!'}
                                  <br />
                                  <br />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )}
                          {stage.matches.length > 0 && stage.id.toString() === query.toString() && (
                            <TableBody>
                              {stage.matches.map((line, i) => (
                                <Fragment key={line.id.toString()}>
                                  {i !== 0 &&
                                  line.round &&
                                  stage.matches[i - 1].round &&
                                  stage.matches[i - 1].round !== line.round ? (
                                    <TableRow>
                                      <TableCell align="center" style={{ background: '#5f5f5f' }} colSpan={7}>
                                        ROUND {line.round} - {new moment(line.deadline).format('MMMM D, YYYY - HH:mm')}
                                      </TableCell>
                                    </TableRow>
                                  ) : i === 0 && line.round ? (
                                    <TableRow>
                                      <TableCell align="center" style={{ background: '#5f5f5f' }} colSpan={7}>
                                        ROUND {line.round} - {new moment(line.deadline).format('MMMM D, YYYY - HH:mm')}
                                      </TableCell>
                                    </TableRow>
                                  ) : (
                                    <Fragment />
                                  )}
                                  <ScoreboardLine
                                    key={line.id.toString()}
                                    account={account}
                                    line={line}
                                    actions={{
                                      onGetMatchMaps,
                                      onEditReport,
                                      onViewReport,
                                      onConfirmReport,
                                    }}
                                  />
                                </Fragment>
                              ))}
                            </TableBody>
                          )}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                {query.toString() === '' && retSort(matches.objects) && (
                  <TableBody>
                    {retSort(matches.objects).map((line, i) => {
                      if (!has_last_games) {
                        has_last_games = line.games_a > 0 || line.games_b > 0;
                      }
                      return (
                        <Fragment key={line.id.toString()}>
                          {(line.games_a > 0 || line.games_b > 0) && (
                            <ScoreboardLine
                              key={line.id.toString()}
                              account={account}
                              line={line}
                              actions={{
                                onGetMatchMaps,
                                onEditReport,
                                onViewReport,
                                onConfirmReport,
                              }}
                            />
                          )}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                )}
              </Fragment>
            )}
            {query.toString() === '' && (matches.objects.length === 0 || !has_last_games) && matches.loaded && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <br />
                    <Warning />
                    <br />
                    {'No games here (yet)!'}
                    <br />
                    <br />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </Paper>
        <Paper className={classes.paper} style={{ maxWidth: this.state ? this.state.windowWidth : 'unset' }}>
          {stage_type === 'single' && !!challonge_url && (
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.head} align="center" colSpan={7}>
                    Bracket
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    <Brackets matches={matches} query={query} />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Paper>
        <MatchViewPopup />
      </Fragment>
    );
  }
}

const stylee = withStyles(styles)(StageGames);
export default connect(
  (state) => {
    return { matches: state.matches, menu: state.menu, account: state.account };
  },
  { ...actions }
)(stylee);
