import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/styles";
import * as actions from "../redux/actions";
import Snackbar from "@material-ui/core/Snackbar";
import {setActiveContent} from "../redux/actions";
import StatusIcon from "./StatusIcon";
import {HowToReg} from "@material-ui/icons";
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
});

class Registration extends Component {
    componentWillUnmount(){
        this.props.clearChangePasswordMessages();
    }

    render() {

        const { state, handleRegPasswordChange, handleRegUserChange, handleRegEmailChange,onReCAPTCHAChange, handleRegPlayerChange, handleRegTeamChange,
            clearRegistrationMessages, onSubmittingRegistration, onSubmittedRegistration } = this.props;
        const { classes } = this.props;

        this.handleSubmit = function(e) {
            clearRegistrationMessages();
            // Set application state before submitting
            e.preventDefault();
            onSubmittingRegistration();
            onSubmittedRegistration({
                username: state.account.reg_user,
                password: state.account.reg_password,
                email: state.account.reg_email,
                nickname: state.account.reg_player,
                team_tag: state.account.reg_team,
            });
        };

        if (state.account.auth_token) {
            setActiveContent({type: 'matches'});
        }
        return (

            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <HowToReg/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Registration
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="User"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            value={state.account.reg_user}
                            onChange={(e) => handleRegUserChange(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="nickname"
                            label="Nick"
                            name="nickname"
                            autoComplete="nickname"
                            value={state.account.reg_player}
                            onChange={(e) => handleRegPlayerChange(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="E-mail"
                            name="email"
                            autoComplete="email"
                            value={state.account.reg_email}
                            onChange={(e) => handleRegEmailChange(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="team"
                            label="Team Tag"
                            name="team"
                            autoComplete="team"
                            value={state.account.reg_team}
                            onChange={(e) => handleRegTeamChange(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={state.account.reg_password}
                            onChange={(e) => handleRegPasswordChange(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary"/>}
                            label="Remember me"
                            checked={true}
                            disabled={true}
                        />
                        <ReCAPTCHA
                            sitekey="6LdrXboUAAAAAL1CLpxTrEjDwOAWWGpYhMwAAWx2"
                            onChange={onReCAPTCHAChange}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.submit}
                            onClick={this.handleSubmit}
                        >
                            Sign In
                        </Button>
                        {state.account.messages.length > 0 && state.account.messages[0].status === "ERROR" &&(
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={state.account.messages.length > 0}
                                autoHideDuration={6000}
                                message={<Typography>{JSON.stringify(state.account.messages[0].text)}</Typography>}
                                className={classes.snackbar}
                            >
                            </Snackbar>
                        )}
                    </form>
                </div>
            </Container>
        );
    }
}

const styled = withStyles(useStyles)(Registration);
export default connect(state => { return {state: state} }, {...actions})(styled);