import {actions} from './actions';
import Link from "@material-ui/core/Link";
import React from "react";

function matches(matches, action){
    switch (action.type){
        case actions.Matches.UPDATE_MATCH_OBJ:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    match_id: action.payload.id.toString(),
                    match_obj: action.payload,
                }
            };
        case actions.Matches.EDITPOPUP_TOGGLE_LOADED:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        loaded: action.payload
                    }
                },

            };
        case actions.Matches.TOGGLE_SUDO_MODE:
            let in_sudo = matches.matchView.editPopupState.sudoMode;
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        sudoMode: !in_sudo,
                    }
                },

            };
        case actions.Matches.ADD_EDIT_REPORT_LINE:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    matchMaps:[
                        ...matches.matchView.matchMaps,
                        action.payload
                    ]
                }
            };
        case actions.Matches.SET_EDITPOPUP_ACTIVE_MSG:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        activeMsg: action.payload
                    }
                },

            };
        case actions.Matches.OPEN_EDIT_REPORT_POPUP:
            let lookup = {};
            lookup[matches.matchView.match_obj.participant_a.id || 'error'] =  matches.matchView.match_obj.participant_a_name || 'error';
            lookup[matches.matchView.match_obj.participant_b.id || 'error'] =  matches.matchView.match_obj.participant_b_name || 'error';
            let columns = [
                {title: 'Valid', type: 'boolean', editable: 'never', field: 'confirmed', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'} },
                {
                    title: 'mvd',
                    field: 'mvd',
                    headerStyle: {textAlign: 'center'},
                    render: rowData => (
                        <div style={{direction: 'rtl',
                            textAlign: 'center',
                            width : '120px',
                            overflow: 'hidden',
                            display: 'inline-block',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap'}}>
                            <Link color="textSecondary" href={rowData.mvdmapstat.mvd ? rowData.mvdmapstat.mvd.replace(':8000', '').replace('http', 'https') : undefined}
                            >{rowData.mvdmapstat.demo_name === 'dummy' ? 'no demo' : rowData.mvdmapstat.demo_name.split('/').slice(-1)[0]}</Link>
                        </div>
                    ),
                    cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px' },
                    editable: 'never',

                },
                {
                    title: 'map',
                    field: 'map',
                    headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                    cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                    editable: 'never'
                },
                {title: 'MVD team A', editable: 'never', field: 'mvdmapstat.guessed_part_1', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'} },
                {title: 'MVD score A', editable: 'never', field: 'mvdmapstat.scores_1', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'} },
                {title: 'MVD team B', editable: 'never', field: 'mvdmapstat.guessed_part_2', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}},
                {title: 'MVD score B', editable: 'never', field: 'mvdmapstat.scores_2', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}},
                {
                    title: 'Winner',
                    field: 'mvdmapstat.part_1.id',
                    headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                    cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px' },
                    lookup: {...lookup},
                    editable: 'always'
                },
                {title: 'Score A', type: 'numeric', hidden: true, editable: 'never', field: 'score_a', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'} },
                {
                    title: 'Loser',
                    field: 'mvdmapstat.part_2.id',
                    headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                    cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                    lookup: {...lookup},
                    editable: 'always'
                },
                {title: 'Score B', type: 'numeric', hidden: true, editable: 'never', field: 'score_b', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'} },
            ];
            let sudoColumns = [...columns];
            sudoColumns[7] = {
                title: 'Winner',
                field: 'mvdmapstat.part_1.id',
                headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px' },
                lookup: {...lookup},
                editable: 'always',
                hidden: true,
            };
            sudoColumns[9] = {
                title: 'Loser',
                field: 'mvdmapstat.part_2.id',
                headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                lookup: {...lookup},
                editable: 'always',
                hidden: true
            };
            sudoColumns[8] = {title: 'Score A', type: 'numeric', hidden: false, editable: 'always', field: 'score_a', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}};
            sudoColumns[2] = {title: 'map', editable: 'always', field: 'map', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}};
            sudoColumns[10] = {title: 'Score B', type: 'numeric', hidden: false, editable: 'always', field: 'score_b', headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'}, cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'} };
            sudoColumns.splice(7,0,{
                title: 'Part A',
                field: 'match.participant_a_name',
                headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px' },
                editable: 'never'
            });
            sudoColumns.splice(9,0,{
                title: 'Part B',
                field: 'match.participant_b_name',
                headerStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px'},
                cellStyle: {textAlign: 'center', padding: '0px, 0px, 0px, 0px' },
                editable: 'never'
            });
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupOpen: true,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        columns: [...columns],
                        sudoColumns: [...sudoColumns],
                        dropZoneDialogOpen: false,
                        ssDropZoneDialogOpen: false,
                        files: [],
                        ssFiles: [],
                        loaded: true,
                    }
                }
            };
        case actions.Matches.DELETED_SCREENSHOTS:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        ssFiles: []
                    }
                }
            };
        case actions.Matches.UPDATING_MATCHMAP:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    loaded: false
                }
            };
        case actions.Matches.UPDATED_MATCHMAP:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    loaded: true
                }
            };
        case actions.Matches.DELETING_MATCHMAP:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    loaded: false
                }
            };
        case actions.Matches.DELETED_MATCHMAP:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    loaded: true
                }
            };
        case actions.Matches.CLOSE_EDIT_REPORT_POPUP:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupOpen: false
                }
            };
        case actions.Matches.CLOSE_DROPZONE_DIALOG:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        dropZoneDialogOpen: false,
                        ssDropZoneDialogOpen: false,
                    }
                }
            };
        case actions.Matches.OPEN_DROPZONE_DIALOG:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        dropZoneDialogOpen: true,
                        activeDropzoneMatchMap: action.payload.matchmap_id
                    }
                }
            };
        case actions.Matches.OPEN_SS_DROPZONE_DIALOG:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        ssDropZoneDialogOpen: true,
                        ssActiveDropzoneMatchMap: action.payload.matchmap_id
                    }
                }
            };
        case actions.Matches.CLOSE_SS_DROPZONE_DIALOG:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        ssDropZoneDialogOpen: false
                    }
                }
            };
        case actions.Matches.ADD_MVD:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        files: action.payload
                    }
                }
            };
        case actions.Matches.ADD_SS:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        ssFiles: action.payload
                    }
                }
            };
        case actions.Matches.VIEW_REPORT:
            return {
                ...matches,
                matchView: {
                    match_id: action.payload.id.toString(),
                    match_obj: action.payload.obj,
                    popupOpen: true,
                    editMode: false,
                    canEdit: !!action.payload.canEdit,
                    loaded: false,
                    matchMaps: []
                }
            };
        case actions.Matches.CREATE_REPORT:
            return {
                ...matches,
                matchView: {
                    match_id: action.payload.id.toString(),
                    match_obj: action.payload.obj,
                    popupOpen: true,
                    editMode: true,
                    canEdit: !!action.payload.canEdit,
                    loaded: false,
                    matchMaps: []
                }
            };
        case actions.Matches.EDIT_REPORT:
            return {
                ...matches,
                matchView: {
                    match_id: action.payload.id.toString(),
                    match_obj: action.payload.obj,
                    popupOpen: true,
                    editMode: true,
                    canEdit: !!action.payload.canEdit,
                    loaded: false,
                    matchMaps: []
                }
            };
        case actions.Matches.CONFIRM_REPORT:
            return {
                ...matches,
            };
        case actions.Matches.CLOSE_REPORT:
            return {
                ...matches,
                matchView: {
                        ...matches.matchView,
                        loaded:false,
                        popupOpen: false,
                        match_obj: {},
                        match_id: '',

                }
            };
        case actions.Matches.GOT_MATCHMAPS:
            return {
                ...matches,
                matchView: {
                    ...matches.matchView,
                    loaded:true,
                    matchMaps: action.payload.json,
                    editPopupState: {
                        ...matches.matchView.editPopupState,
                        loaded: true,
                    }
                }
            };
        case actions.Matches.ON_GETTING_MATCHES:
            return {
                ...matches,
                loaded: false
            };
        case actions.Matches.GOT_STAGES:
            let new_objects = [];
            let event = '';
            let tour = '';
            let stage = '';
            let last_event = '';
            let challonge_url = '';
            let stage_type = '';
            let svg = '';
            action.payload.forEach( (item) => {
                tour = item.name.split(" - ")[0];
                stage = item.name.split(" - ")[1];
                event = item.tournament.id.toString();
                challonge_url = item.challonge_url;
                stage_type = item.stage_type;
                svg = item.svg;
                if (last_event !== event) {
                    new_objects.push(
                        {
                            event: event.toString(),
                            tournament: tour,
                            stages: [{id: item.id, name: stage, matches: [], challonge_url: challonge_url, stage_type: stage_type, svg: svg}],
                            season: item.tournament.event_id.toString(),
                            seasonName: item.tournament.event_name
                        }
                    );
                } else {
                    new_objects[new_objects.length-1].stages.push({id: item.id, name: stage, matches: [], challonge_url: challonge_url, stage_type: stage_type, svg: svg});
                }
                last_event = event;
            });
            return {
                ...matches,
                objects: new_objects,
                stages_loaded: true,
            };
        case actions.Matches.GOT_MATCHES:
            const {json, stage_id} = action.payload;
            let new_matches = matches;
            new_matches.objects.forEach((tour) => {
                tour.stages.forEach((stage) => {
                    if (stage_id) {
                        if (stage.id.toString() === stage_id) {
                            stage.matches = [];
                            json.forEach((match) => {
                                stage.matches.push(match);
                            });
                        }
                    } else {
                        stage.matches = [];
                        json.forEach((match) => {
                            if (match.stage_id === stage.id)
                            stage.matches.push(match);
                        });
                    }
                })

            });
            return Object.assign({},
                {...new_matches, loaded: true}
                );
        default:
            return matches
    }
}

function account(account, action){
    switch (action.type) {
        case actions.Account.LOGOUT:
            return {
                ...account,
                auth_token: '',
                is_tournament_admin: '',
                is_captain: '',
                user_id: '',
                user_name: '',
                user_email: '',
            };
        case actions.Account.REG_EMAIL_FIELD_CHANGED:
            return {
                ...account,
                reg_email: action.payload,
            };
        case actions.Account.RECAPTCHA_FIELD_CHANGE:
            return {
                ...account,
                recaptcha: action.payload,
            };
        case actions.Account.REG_PASSWORD_FIELD_CHANGED:
            return {
                ...account,
                reg_password: action.payload,
            };
        case actions.Account.REG_PLAYER_FIELD_CHANGED:
            return {
                ...account,
                reg_player: action.payload,
            };
        case actions.Account.REG_TEAM_FIELD_CHANGED:
            return {
                ...account,
                reg_team: action.payload,
            };
        case actions.Account.REG_USER_FIELD_CHANGED:
            return {
                ...account,
                reg_user: action.payload,
            };
        case actions.Account.NEW_PASSWORD_FIELD_CHANGED:
            return {
                ...account,
                new_password: action.payload,
            };
        case actions.Account.OLD_PASSWORD_FIELD_CHANGED:
            return {
                ...account,
                old_password: action.payload,
            };
        case actions.Account.LOGIN_USER_FIELD_CHANGED:
            return {
                ...account,
                login_user: action.payload,
            };
        case actions.Account.LOGIN_PASSWORD_FIELD_CHANGED:
            return {
                ...account,
                login_password: action.payload,
            };
        case actions.Account.SUBMITTING_CHANGE_PASSWORD:
            return {
                ...account,
                loaded: false,
            };
        case actions.Account.SUBMITTING_REGISTRATION:
            return {
                ...account,
                loaded: false,
            };
        case actions.Account.SUBMITTING_LOGIN:
            return {
                ...account,
                loaded: false,
            };
        case actions.Account.PASSWORD_UPDATE_FAILED:
            return {
                ...account,
                loaded: true,
                messages: [{status: 'ERROR', text: action.payload}],
                new_password: '',
                old_password: '',
            };
        case actions.Account.REGISTRATION_FAILED:
            return {
                ...account,
                loaded: true,
                messages: [{status: 'ERROR', text: action.payload}],
            };
        case actions.Account.REGISTRATION_SUCCESS:
            return {
                ...account,
                loaded: true,
                reg_email: '',
                reg_password: '',
                reg_player: '',
                reg_team: '',
                reg_user: '',
                messages: [{status: 'RESPONSE', text: JSON.stringify(action.payload)}],
            };
        case actions.Account.PASSWORD_UPDATED:
            return {
                ...account,
                loaded: true,
                auth_token: '',
                is_tournament_admin: '',
                is_captain: '',
                user_id: '',
                user_name: '',
                user_email: '',
                messages: [{status: 'RESPONSE', text: JSON.stringify(action.payload)}],
                new_password: '',
                old_password: ''
            };
        case actions.Account.LOGIN_FAILED:
            return {
                ...account,
                loaded: true,
                messages: [{status: 'ERROR', text: action.payload}]
            };
        case actions.Account.CLEAR_CHANGE_PASSWORD_MESSAGES || actions.Account.CLEAR_LOGIN_MESSAGES | actions.Account.CLEAR_REGISTRATION_MESSAGES:
            return {
                ...account,
                messages: []
            };
        case actions.Account.LOGGED_IN:
            if (!action.payload.token) {
                return account
            }
            return {
                ...account,
                auth_token: action.payload.token,
                user_id: action.payload.user_id,
                user_name: action.payload.name,
                user_email: action.payload.email,
                is_tournament_admin: action.payload.is_tournament_admin,
                is_captain: action.payload.is_captain,
                profile: action.payload.profile,
                loaded: true,
                login_user: '',
                login_password: ''
            };
        default:
            return account;
    }
}

function menu(menu, action){
    switch (action.type) {
        case actions.Menu.TOGGLE_MENU:
            menu.menuOpen[action.payload] = !menu.menuOpen[action.payload];
            return Object.assign({}, menu);
        case actions.Menu.SET_ACTIVE:
            return {
                ...menu,
                activeContent: action.payload
            };
        case actions.Menu.CLICK_SEASON_MENU:
            return {
                ...menu,
                anchorEl: action.payload
            };
        case actions.Menu.CLOSE_SEASON_MENU:
            return {
                ...menu,
                anchorEl: null
            };
        case actions.Menu.SET_ACTIVE_SEASON:
            return {
                ...menu,
                activeSeason: action.payload[0],
                activeSeasonName: action.payload[1]
            };
        case actions.Menu.SET_ERROR_DIALOG:
            return {
                ...menu,
                errorDialog: {
                    open: action.payload.open,
                    msg: action.payload.msg
                }
            };
        default:
            return menu;
    }
}

function announcements(announcements, action){
    switch (action.type) {
        case actions.Announcements.GOT_ANNOUNCEMENTS:
            return {
                ...announcements,
                loaded: true,
                items: action.payload.page === "1" ? action.payload.json : announcements.items.concat(action.payload.json),
                count: action.payload.count,
                new_announcement_field: '',
                new_announcement_title_field: '',
                submitted_once: false
            };
        case actions.Announcements.ON_GETTING_ANNOUNCEMENTS:
            return {
                ...announcements,
                loaded: false
            };
        case actions.Announcements.ON_POSTED_ANNOUNCEMENT:
            return {
                ...announcements,
                loaded: true
            };
        case actions.Announcements.SUBMITTING_NEW_ANNOUNCEMENT:
            return {
                ...announcements,
                submitted_once: true,
            };

        case actions.Announcements.CLEAR_ANNOUNCEMENTS:
            return {
                items: [],
                count: undefined,
                loaded: false,
                active_match: undefined,
                new_announcement_field: '',
                new_announcement_text_field: '',
                submitted_once: ''
            };
        case actions.Announcements.NEW_ANNOUNCEMENT_FIELD_CHANGE:
            return {
                ...announcements,
                new_announcement_field: action.payload,
            };
        case actions.Announcements.NEW_ANNOUNCEMENT_TITLE_FIELD_CHANGE:
            return {
                ...announcements,
                new_announcement_title_field: action.payload,
            };


        default:
            return announcements;
    }
}

function participants(participants, action){
    switch (action.type) {
        case actions.Participants.ON_GETTING_PARTICIPANTS:
            return {
                ...participants,
                loaded: false,
                objects: []
            };
        case actions.Participants.GOT_PARTICIPANTS:
            return {
                ...participants,
                objects: action.payload,
                loaded: true
            };
        default:
            return participants;
    }
}
export default function getQuadApp(state = {}, action) {
    return {
        matches: matches(state.matches, action),
        menu: menu(state.menu, action),
        account: account(state.account, action),
        announcements: announcements(state.announcements, action),
        participants: participants(state.participants, action)
    }
}
