import React, { Component, Fragment, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import LinearProgress from '@material-ui/core/LinearProgress';
import { styled, withStyles } from '@material-ui/styles';
import styledc from 'styled-components';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';

const LoadingLine = styled(LinearProgress)({
  marginTop: '10px',
  marginBottom: '10px',
  width: '100%',
});

const HoveredTableRow = styledc(TableRow)`
    background-color: #424242;
    :hover {
        background-color: rgba(51,0,102,0.2);
    }
`;

const styles = (theme) => ({
  paper: {
    //width: '65%',
    overflowX: 'auto',
    marginLeft: '5px',
    marginRight: '5px',
    marginBottom: '10px',
  },
  table: {
    //minWidth: 650,
    minWidth: 'max-content',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
});

class Teams extends Component {
  handleResize = (e) => {
    this.setState({ windowWidth: window.innerWidth > 0 ? window.innerWidth : window.screen.width });
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('orientationchange', this.handleResize);
  }

  componentDidMount() {
    this.props.onGettingParticipants();
    this.props.onGetBigParticipants();
    this.setState({ windowWidth: window.innerWidth > 0 ? window.innerWidth : window.screen.width });
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('orientationchange', this.handleResize);
  }

  render() {
    const { participants, classes, matches } = this.props;

    let last_tour = '';
    let tours = [];
    let with_stats = {};
    let orderedParticipants = [];
    if (participants.loaded && participants.objects.length > 0) {
      orderedParticipants = participants.objects.slice().sort((a, b) => {
        if (a.tournament.name < b.tournament.name) {
          return -1;
        }
        if (a.tournament.name > b.tournament.name) {
          return 1;
        }
        return 0;
      });
      orderedParticipants.forEach((part) => {
        if (part.tournament.name !== last_tour) {
          last_tour = part.tournament.name;
          tours.push(part.tournament.name);
        }
      });
      orderedParticipants.forEach((part) => {
        part.mvdplayerstat_set.sort((a, b) => (a.total_frags > b.total_frags ? -1 : 1));
      });
      orderedParticipants.forEach((part) => {
        part.players.sort((a, b) => (a.name < b.name ? -1 : 1));
      });
    }

    if (!participants.loaded || !matches.stages_loaded) {
      return (
        <Paper className={classes.paper} style={{ maxWidth: this.state ? this.state.windowWidth : 'unset' }}>
          <Table className={classes.table} size="small">
            <TableHead>
              <TableRow>
                <TableCell className={classes.head} align="center" colSpan={10}>
                  -
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Player
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Maps
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Avg Effi
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Avg ToDie
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Total eWep
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Total Given
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Total Taken
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Total RL Kls
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Avg TimeToWeap
                </TableCell>
                <TableCell align="center" style={{ color: 'darkgrey' }}>
                  Total Frags
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <LoadingLine />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <LoadingLine />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <LoadingLine />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <LoadingLine />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <LoadingLine />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={10}>
                  <LoadingLine />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      );
    } else {
      return (
        <Fragment>
          {tours.map((tour, i) => (
            <Paper
              key={i.toString()}
              className={classes.paper}
              style={{ maxWidth: this.state ? this.state.windowWidth : 'unset' }}
            >
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head} align="center" colSpan={10}>
                      {tour}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {participants.objects.length > 0 &&
                    participants.loaded &&
                    orderedParticipants.length > 0 &&
                    orderedParticipants.map((part, tk) => {
                      if (part.tournament.name === tour) {
                        if (!with_stats[part.team.name]) {
                          with_stats[part.team.name] = [];
                        }
                        return (
                          <Fragment key={tk.toString()}>
                            <TableRow>
                              <TableCell align="center" colSpan={10} style={{ background: '#5f5f5f' }}>
                                {part.team.name}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Player
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Maps
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Avg Effi
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Avg ToDie
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Total eWep
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Total Given
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Total Taken
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Total RL Kls
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Avg TimeToWeap
                              </TableCell>
                              <TableCell align="center" style={{ color: 'darkgrey' }}>
                                Total Frags
                              </TableCell>
                            </TableRow>
                            {part.mvdplayerstat_set.length > 0 &&
                              part.mvdplayerstat_set.map((pstat, ix) => {
                                with_stats[part.team.name].push(pstat.player.name);
                                return (
                                  <HoveredTableRow key={ix.toString()}>
                                    <TableCell align="center">{pstat.player.name}</TableCell>
                                    <TableCell align="center">{pstat.total_games}</TableCell>
                                    <TableCell align="center">{pstat.avg_effi.toFixed(2) + '%'}</TableCell>
                                    <TableCell align="center">{pstat.avg_todie.toFixed(0)}</TableCell>
                                    <TableCell align="center">{pstat.total_ewep}</TableCell>
                                    <TableCell align="center">{pstat.total_gvn}</TableCell>
                                    <TableCell align="center">{pstat.total_tkn}</TableCell>
                                    <TableCell align="center">{pstat.total_rlkilled}</TableCell>
                                    <TableCell align="center">{pstat.avg_wtake_time.toFixed(2)}</TableCell>
                                    <TableCell align="center">{pstat.total_frags}</TableCell>
                                  </HoveredTableRow>
                                );
                              })}
                            {part.players.length > 0 &&
                              part.players.map((pla, index) => {
                                if (with_stats[part.team.name] && with_stats[part.team.name].indexOf(pla.name) < 0) {
                                  return (
                                    <HoveredTableRow key={index.toString()}>
                                      <TableCell align="center">{pla.name}</TableCell>
                                      <TableCell align="center">-</TableCell>
                                      <TableCell align="center">-</TableCell>
                                      <TableCell align="center">-</TableCell>
                                      <TableCell align="center">-</TableCell>
                                      <TableCell align="center">-</TableCell>
                                      <TableCell align="center">-</TableCell>
                                      <TableCell align="center">-</TableCell>
                                      <TableCell align="center">-</TableCell>
                                      <TableCell align="center">-</TableCell>
                                    </HoveredTableRow>
                                  );
                                } else {
                                  return <Fragment key={index.toString()} />;
                                }
                              })}
                            {!(part.mvdplayerstat_set.length > 0) && !(part.players.length > 0) && (
                              <TableRow>
                                <TableCell align="center" colSpan={10}>
                                  -
                                </TableCell>
                              </TableRow>
                            )}
                          </Fragment>
                        );
                      }
                    })}
                </TableBody>
              </Table>
            </Paper>
          ))}
        </Fragment>
      );
    }
  }
}

const stylee = withStyles(styles)(Teams);
export default connect(
  (state) => {
    return { participants: state.participants, matches: state.matches };
  },
  { ...actions }
)(stylee);
