import React, {Component} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/styles";
import * as actions from "../redux/actions";
import Snackbar from "@material-ui/core/Snackbar";
import ReCAPTCHA from "react-google-recaptcha";


const useStyles = theme => ({
    // '@global': {
    //     body: {
    //         backgroundColor: theme.palette.common.white,
    //     },
    // },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    snackbar: {
        backgroundColor: theme.palette.error.dark
    }
});

class ChangePassword extends Component {

    componentWillUnmount(){
        this.props.clearChangePasswordMessages();
    }
    render() {


        const { state, handleSnackBarClose, clearChangePasswordMessages, handleOldPasswordChange, onReCAPTCHAChange, handleNewPasswordChange, onSubmittingChangePassword, onSubmittedChangePassword } = this.props;

        this.handleSubmit = function(e) {
            clearChangePasswordMessages();
            // Set application state before submitting
            e.preventDefault();
            onSubmittingChangePassword();
            onSubmittedChangePassword({data: {old_password: state.account.old_password, new_password: state.account.new_password},
                                        user_id: state.account.user_id});
        };

        const { classes } = this.props;

        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline/>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Change Password
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            type="password"
                            required
                            fullWidth
                            id="old_password"
                            label="Old Password"
                            name="old_password"
                            autoFocus
                            value={state.account.old_password}
                            onChange={(e) => handleOldPasswordChange(e.target.value)}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="new_password"
                            label="New Password"
                            type="password"
                            id="new_password"
                            value={state.account.new_password}
                            onChange={(e) => handleNewPasswordChange(e.target.value)}
                        />
                        <ReCAPTCHA
                            sitekey="6LdrXboUAAAAAL1CLpxTrEjDwOAWWGpYhMwAAWx2"
                            onChange={onReCAPTCHAChange}
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.submit}
                            onClick={this.handleSubmit}
                        >
                            SUBMIT
                        </Button>
                        {state.account.messages.length > 0 && state.account.messages[0].status === "ERROR" &&(
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            open={state.account.messages.length > 0}
                            autoHideDuration={6000}
                            message={<Typography>{JSON.stringify(state.account.messages[0].text)}</Typography>}
                            className={classes.snackbar}
                        >
                        </Snackbar>
                        )}
                    </form>
                </div>
            </Container>
        );
    }
}

const styled = withStyles(useStyles)(ChangePassword);
export default connect(state => { return {state: state} }, {...actions})(styled);