//****************************
//Action types
//****************************

const API_HOST_PORT = "getquad.quake.world";
const HTTP_STRING = "https://";

export const actions = {
    Matches: {
        UPDATE_MATCH_OBJ: 'UPDATE_MATCH_OBJ',
        GOT_MATCHES: 'GOT_MATCHES',
        GOT_STAGES: 'GOT_STAGES',
        ON_GETTING_MATCHES: 'ON_GETTING_MATCHES',
        EDIT_REPORT: 'EDIT_REPORT',
        CREATE_REPORT: 'CREATE_REPORT',
        CONFIRM_REPORT: 'CONFIRM_REPORT',
        VIEW_REPORT: 'VIEW_REPORT',
        GOT_MATCHMAPS: 'GOT_MATCHMAPS',
        CLOSE_REPORT: 'CLOSE_REPORT',
        OPEN_EDIT_REPORT_POPUP: 'OPEN_EDIT_REPORT_POPUP',
        CLOSE_EDIT_REPORT_POPUP: 'CLOSE_EDIT_REPORT_POPUP',
        DELETING_MATCHMAP: 'DELETING_MATCHMAP',
        DELETED_MATCHMAP: 'DELETED_MATCHMAP',
        CLOSE_DROPZONE_DIALOG: 'CLOSE_DROPZONE_DIALOG',
        OPEN_DROPZONE_DIALOG: 'OPEN_DROPZONE_DIALOG',
        OPEN_SS_DROPZONE_DIALOG: 'OPEN_SS_DROPZONE_DIALOG',
        CLOSE_SS_DROPZONE_DIALOG: 'CLOSE_SS_DROPZONE_DIALOG',
        ADD_MVD: 'ADD_MVD',
        ADD_SS: 'ADD_SS',
        SET_EDITPOPUP_ACTIVE_MSG: 'SET_EDITPOPUP_ACTIVE_MSG',
        ADD_EDIT_REPORT_LINE: 'ADD_EDIT_REPORT_LINE',
        TOGGLE_SUDO_MODE: 'TOGGLE_SUDO_MODE',
        UPDATING_MATCHMAP: 'UPDATING_MATCH_MAP',
        UPDATED_MATCHMAP: 'UPDATED_MATCHMAP',
        DELETED_SCREENSHOTS: 'DELETED_SCREENSHOTS',
        EDITPOPUP_TOGGLE_LOADED: 'EDITPOPUP_TOGGLE_LOADED'
    },
    Account: {
        PASSWORD_UPDATED: 'PASSWORD_UPDATED',
        SUBMITTING_CHANGE_PASSWORD: 'SUBMITTING_CHANGE_PASSWORD',
        PASSWORD_UPDATE_FAILED: 'PASSWORD_UPDATE_FAILED',
        LOGGED_IN: 'LOGGED_IN',
        LOGIN_FAILED: 'LOGIN_FAILED',
        NEW_PASSWORD_FIELD_CHANGED: 'NEW_PASSWORD_FIELD_CHANGED',
        OLD_PASSWORD_FIELD_CHANGED: 'OLD_PASSWORD_FIELD_CHANGED',
        LOGIN_USER_FIELD_CHANGED: 'LOGIN_USER_FIELD_CHANGED',
        LOGIN_PASSWORD_FIELD_CHANGED: 'LOGIN_PASSWORD_FIELD_CHANGED',
        SUBMITTING_LOGIN: 'SUBMITTING_LOGIN',
        LOGOUT: 'LOGOUT',
        CLEAR_CHANGE_PASSWORD_MESSAGES: 'CLEAR_CHANGE_PASSWORD_MESSAGES',
        CLEAR_LOGIN_MESSAGES: 'CLEAR_LOGIN_MESSAGES',
        CLEAR_REGISTRATION_MESSAGES: 'CLEAR_REGISTRATION_MESSAGES',
        REG_PASSWORD_FIELD_CHANGED: 'REG_PASSWORD_FIELD_CHANGED',
        REG_EMAIL_FIELD_CHANGED: 'REG_EMAIL_FIELD_CHANGED',
        REG_USER_FIELD_CHANGED: 'REG_USER_FIELD_CHANGED',
        REG_PLAYER_FIELD_CHANGED: 'REG_PLAYER_FIELD_CHANGED',
        REG_TEAM_FIELD_CHANGED: 'REG_TEAM_FIELD_CHANGED',
        SUBMITTING_REGISTRATION: 'SUBMITTING_REGISTRATION',
        REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
        REGISTRATION_FAILED: 'REGISTRATION_FAILED',
        RECAPTCHA_FIELD_CHANGE: 'RECAPTCHA_FIELD_CHANGE'


    },
    Menu: {
        TOGGLE_MENU: 'TOGGLE_MENU',
        SET_ACTIVE: 'SET_ACTIVE',
        SET_ANCHOREL: 'SET_ANCHOREL',
        CLICK_SEASON_MENU: 'CLICK_SEASON_MENU',
        CLOSE_SEASON_MENU: 'CLOSE_SEASON_MENU',
        SET_ACTIVE_SEASON: '',
        SET_ERROR_DIALOG: 'SET_ERROR_DIALOG'
    },
    Announcements: {
        GOT_ANNOUNCEMENTS: 'GOT_ANNOUNCEMENTS',
        ON_GETTING_ANNOUNCEMENTS: 'ON_GETTING_ANNOUNCEMENTS',
        CLEAR_ANNOUNCEMENTS: 'CLEAR_ANNOUNCEMENTS',
        NEW_ANNOUNCEMENT_FIELD_CHANGE: 'NEW_ANNOUNCEMENT_FIELD_CHANGE',
        SUBMITTING_NEW_ANNOUNCEMENT: 'SUBMITTING_NEW_ANNOUNCEMENT',
        ON_POSTED_ANNOUNCEMENT: 'ON_POSTED_ANNOUNCEMENT',
        NEW_ANNOUNCEMENT_TITLE_FIELD_CHANGE: 'NEW_ANNOUNCEMENT_TITLE_FIELD_CHANGE',

    },
    Participants:
        {
        GOT_PARTICIPANTS: 'GOT_PARTICIPANTS',
        ON_GETTING_PARTICIPANTS: 'ON_GETTING_PARTICIPANTS'
    }
};


//****************************
//regular action-creators
//****************************

export function onGettingMatches() {
    return {type: actions.Matches.ON_GETTING_MATCHES, payload: undefined};
}

export function onGettingParticipants() {
    return {type: actions.Participants.ON_GETTING_PARTICIPANTS, payload: undefined};
}

export function onToggleEditPopupLoaded(payload){
    return {type: actions.Matches.EDITPOPUP_TOGGLE_LOADED, payload}
}

export function onUpdateMatchObj(payload){
    return {type: actions.Matches.UPDATE_MATCH_OBJ, payload}
}

//expects match_id
export function onViewReport(payload) {
    return {type: actions.Matches.VIEW_REPORT, payload}
}

//expects nothing
export function onCloseMatchViewPopup(payload) {
    return {type: actions.Matches.CLOSE_REPORT, payload}
}

export function onOpenMatchViewEditPopup(payload) {
    return {type: actions.Matches.OPEN_EDIT_REPORT_POPUP, payload}
}

export function onDeletingMatchMap(payload) {
    return {type: actions.Matches.DELETING_MATCHMAP, payload}
}

export function onUpdatingMatchMap(payload) {
    return {type: actions.Matches.UPDATING_MATCH_MAP, payload}
}

export function onCloseDropZoneDialog(payload) {
    return {type: actions.Matches.CLOSE_DROPZONE_DIALOG, payload}
}

export function onOpenDropZoneDialog(payload) {
    return {type: actions.Matches.OPEN_DROPZONE_DIALOG, payload}
}
export function onCloseSSDropZoneDialog(payload) {
    return {type: actions.Matches.CLOSE_SS_DROPZONE_DIALOG, payload}
}

export function onOpenSSDropZoneDialog(payload) {
    return {type: actions.Matches.OPEN_SS_DROPZONE_DIALOG, payload}
}


//expects match_id
export function onConfirmReport(payload) {
    return {type: actions.Matches.CONFIRM_REPORT, payload}
}

export function handleClickMenu(payload) {
    return {type: actions.Menu.CLICK_SEASON_MENU, payload}
}

export function handleCloseMenu(payload) {
    return {type: actions.Menu.CLOSE_SEASON_MENU, payload}
}

export function setActiveContent(payload) {
    return {type: actions.Menu.SET_ACTIVE, payload}
}

export function onClickMenu(payload) {
    return {type: actions.Menu.TOGGLE_MENU, payload}
}

export function onSubmittingChangePassword(payload) {
    return {type: actions.Account.SUBMITTING_CHANGE_PASSWORD, payload}
}

export function onSubmittingRegistration(payload) {
    return {type: actions.Account.SUBMITTING_REGISTRATION, payload}
}

export function handleNewPasswordChange(payload) {
    return {type: actions.Account.NEW_PASSWORD_FIELD_CHANGED, payload}
}

export function handleOldPasswordChange(payload) {
    return {type: actions.Account.OLD_PASSWORD_FIELD_CHANGED, payload}
}

export function onSubmittingLogin(payload) {
    return {type: actions.Account.SUBMITTING_LOGIN, payload}
}

export function handleLoginUserChange(payload) {
    return {type: actions.Account.LOGIN_USER_FIELD_CHANGED, payload}
}

export function handleLoginPasswordChange(payload) {
    return {type: actions.Account.LOGIN_PASSWORD_FIELD_CHANGED, payload}
}
export function onReCAPTCHAChange(payload) {
    return {type: actions.Account.RECAPTCHA_FIELD_CHANGE, payload}
}

export function clearChangePasswordMessages(payload) {
    return {type: actions.Account.CLEAR_CHANGE_PASSWORD_MESSAGES, payload}
}

export function clearLoginMessages(payload) {
    return {type: actions.Account.CLEAR_LOGIN_MESSAGES, payload}
}

export function onLogOut(payload) {
    localStorage.setItem('auth_token', '');
    localStorage.setItem('user_id', '');
    localStorage.setItem('user_name', '');
    localStorage.setItem('user_email', '');
    localStorage.setItem('is_tournament_admin', '');
    localStorage.setItem('is_captain', '');
    localStorage.setItem('profile', '{}');

    return {type: actions.Account.LOGOUT, payload}
}

export function onGettingAnnouncements() {
    return {type: actions.Announcements.ON_GETTING_ANNOUNCEMENTS, payload: undefined};
}

export function clearAnnouncements() {
    return {type: actions.Announcements.CLEAR_ANNOUNCEMENTS, payload: undefined};
}

export function handleNewAnnouncementChange(payload) {
    return {type: actions.Announcements.NEW_ANNOUNCEMENT_FIELD_CHANGE, payload}
}

export function handleNewAnnouncementTitleChange(payload) {
    return {type: actions.Announcements.NEW_ANNOUNCEMENT_TITLE_FIELD_CHANGE, payload}
}

export function onSubmittingAnnouncement(payload) {
    return {type: actions.Announcements.SUBMITTING_NEW_ANNOUNCEMENT, payload}
}

export function handleRegPasswordChange(payload) {
    return {type: actions.Account.REG_PASSWORD_FIELD_CHANGED, payload}
}

export function handleRegUserChange(payload) {
    return {type: actions.Account.REG_USER_FIELD_CHANGED, payload}
}

export function handleRegEmailChange(payload) {
    return {type: actions.Account.REG_EMAIL_FIELD_CHANGED, payload}
}

export function handleRegPlayerChange(payload) {
    return {type: actions.Account.REG_PLAYER_FIELD_CHANGED, payload}
}

export function handleRegTeamChange(payload) {
    return {type: actions.Account.REG_TEAM_FIELD_CHANGED, payload}
}

export function clearRegistrationMessages(payload) {
    return {type: actions.Account.CLEAR_REGISTRATION_MESSAGES, payload}
}

export function onAddMVD(payload) {
    return {type: actions.Matches.ADD_MVD, payload}
}

export function onAddSS(payload) {
    return {type: actions.Matches.ADD_SS, payload}
}

export function onErrorDialogClose(payload) {
    return {type: actions.Menu.SET_ERROR_DIALOG, payload: {open: false, msg: ''}}
}

export function onErrorDialogOpen(payload) {
    return {type: actions.Menu.SET_ERROR_DIALOG, payload: {open: true, msg: payload}}
}

export function setActiveEditPopupMsg(payload) {
    return {type: actions.Matches.SET_EDITPOPUP_ACTIVE_MSG, payload: payload}
}

export function updateEditRowData(payload) {
    return {type: actions.Matches.ADD_EDIT_REPORT_LINE, payload: payload}
}

export function onSudoModeToggle(payload) {
    return {type: actions.Matches.TOGGLE_SUDO_MODE, payload: payload}
}

//****************************
//redux-thunk action creators
//****************************
// Expect query parameter to fetch matches
export function onGetStages() {
    return function (dispatch, getState) {
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/stages/")
            .catch(e => console.log(e.message))
            .then(response => response.json())
            .catch(e => console.log(e.message))
            .then(async json => {
                let parsed = await Promise.all(json.map(async item => {
                    if (!!item.challonge_url && item.stage_type === 'single') {
                      item.svg = (item.challonge_url);
                    } else {
                        item.svg = '';
                    }
                    return item;
                }));
              return parsed.sort((a, b) => a.tournament.event_name > b.tournament.event_name ? 1 : -1);
            })
            .then(parsed => {
                dispatch({type: actions.Matches.GOT_STAGES, payload: parsed});
            });
    };
}

export function onGetBigParticipants() {
    return function (dispatch, getState) {
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/participantsbig?event_id=".concat(getState().menu.activeSeason))
            .then(response => response.json())
            .then(json => {
                dispatch({type: actions.Participants.GOT_PARTICIPANTS, payload: json});
            });
    };
}



export function onGetParticipants() {
    return function (dispatch, getState) {
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/participants?event_id=".concat(getState().menu.activeSeason))
            .then(response => response.json())
            .then(json => {
                dispatch({type: actions.Participants.GOT_PARTICIPANTS, payload: json});
            });
    };
}

export function onGetMatches(payload) {
    const q = payload ? payload.toString() : '';
    return function (dispatch, getState) {
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/matches?stage_id=".concat(q).concat("&event_id=").concat(getState().menu.activeSeason))
            .then(response => response.json())
            .then(json => {
                dispatch({type: actions.Matches.GOT_MATCHES, payload: {json: json, stage_id: q}});
            });
    };
}

export function onGetAnnouncements(payload) {
    return function (dispatch, getState) {
        //     const myInit = {
        //         method: "GET",
        //         headers: {"Authorization": "Token ".concat(getState().auth_token),
        //         "Content-Type": "application/json",},
        //     mode: "same-origin",
        // };
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/announcements/?match_id=".concat(payload.match_id || getState().matches.matchView.match_id || "null").concat("&page=").concat(payload.page).concat("&page_size=").concat(payload.page_size))
            .then(response => response.json())
            .then(json => {
                dispatch({
                    type: actions.Announcements.GOT_ANNOUNCEMENTS,
                    payload: {json: json.results, count: json.count, page: payload.page}
                });
            });
    };
}

// Expects payload with body data and user_id
export function onSubmittedChangePassword(payload) {
    return function (dispatch, getState) {
        payload.data['g-recaptcha-response'] = getState().account.recaptcha;
        const myInit = {
            method: "POST",
            headers: {
                "Authorization": "Token ".concat(getState().account.auth_token),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload.data)
        };
        return fetch(`https://` + API_HOST_PORT + `/api/users/${payload.user_id}/password/`, myInit)
            .then(response => {
                return {data: response.json(), ok: response.ok}
            })
            .then(json => {
                if (json.ok) {
                    dispatch({type: actions.Account.PASSWORD_UPDATED, payload: json.data});
                    localStorage.setItem('auth_token', '');
                    localStorage.setItem('user_id', '');
                    localStorage.setItem('user_name', '');
                    localStorage.setItem('user_email', '');
                    localStorage.setItem('is_tournament_admin', '');
                    localStorage.setItem('is_captain', '');
                    dispatch(setActiveContent({type: 'SignIn'}));
                } else {
                    dispatch({type: actions.Account.PASSWORD_UPDATE_FAILED, payload: "Error: bad password, try again."})
                }
            })
            .catch(e => dispatch({type: actions.Account.PASSWORD_UPDATE_FAILED, payload: e.messages[0]}));
    };
}

//Expects object {"username": "username", "password": "password"}
export function onLoggedIn(data) {
    return function (dispatch, getState) {
        data.data['g-recaptcha-response'] = getState().account.recaptcha;
        const myInit = {
            method: "POST",
            headers: {"Content-Type": "application/json",},
            body: JSON.stringify(data.data)
        };
        return fetch(HTTP_STRING + API_HOST_PORT + "/api-token-auth/", myInit)
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    return dispatch({type: actions.Account.LOGIN_FAILED, payload: "Login Failed"})
                }
            })
            .then(json => {
                if (json.token) {
                    dispatch({type: actions.Account.LOGGED_IN, payload: json});
                    localStorage.setItem('auth_token', json.token);
                    localStorage.setItem('user_id', json.user_id);
                    localStorage.setItem('user_name', json.name);
                    localStorage.setItem('user_email', json.email);
                    localStorage.setItem('is_tournament_admin', json.is_tournament_admin);
                    localStorage.setItem('is_captain', json.is_captain);
                    localStorage.setItem('profile', JSON.stringify(json.profile));
                    dispatch(setActiveContent({type: 'matches', payload: ''}));
                } else {

                }

            })
            .catch(e => dispatch({type: actions.Account.LOGIN_FAILED, payload: e.message}))
            ;
    };
}

//Expects object {"text": "text", "match": "match_id"}
export function onNewAnnouncement(payload) {
    return function (dispatch, getState) {
        if (!payload.match) {
            payload['match'] = getState().matches.matchView.match_id;
        }
        const myInit = {
            method: "POST",
            headers: {
                "Authorization": "Token ".concat(getState().account.auth_token),
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload)
        };
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/announcements/", myInit)
            .then(response => response.json())
            .then(json => {
                //dispatch( clearAnnouncements());
                dispatch(onGetAnnouncements({match_id: getState().matches.matchView.match_id || 'null', page: "1", page_size: "5"}));
            });
    };
}

// Expects {"username": "Miltonenenen",
// 		"password": "lala",
// 	"email": "lala@loenlenena.com",
// 	"nickname": "Miltonenenen"
// }
export function onSubmittedRegistration(payload) {
    return function (dispatch, getState) {
        payload['g-recaptcha-response'] = getState().account.recaptcha;
        const myInit = {
            method: "POST",
            headers: {"Content-Type": "application/json",},
            body: JSON.stringify(payload)
        };
        return fetch(`https://` + API_HOST_PORT + `/api/userprofiles/`, myInit)
            .then(response => {
                return {data: response.json(), ok: response.ok}
            })
            .then(json => {
                if (json.ok) {
                    dispatch({type: actions.Account.REGISTRATION_SUCCESS, payload: json.data});
                    localStorage.setItem('auth_token', '');
                    localStorage.setItem('user_id', '');
                    localStorage.setItem('user_name', '');
                    localStorage.setItem('user_email', '');
                    localStorage.setItem('is_tournament_admin', '');
                    dispatch(setActiveContent({type: 'SignIn'}));
                } else {
                    dispatch({type: actions.Account.REGISTRATION_FAILED, payload: "Error: request, try again."})
                }
            })
            .catch(e => dispatch({type: actions.Account.REGISTRATION_FAILED, payload: e.messages[0]}));
    };
}

//Getting MatchMaps
//expects match_id
export function onGetMatchMaps(payload) {
    const q = payload ? payload.toString() : '';
    return function (dispatch, getState) {
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/matchmaps?match_id=".concat(q))
            .then(response => response.json())
            .then(json => {
                if (json.length > 0) {
                    dispatch({
                        type: actions.Matches.UPDATE_MATCH_OBJ,
                        payload: json[0].match
                    });
                }

                dispatch({type: actions.Matches.GOT_MATCHMAPS, payload: {json: json, match_id: q}});
            });
    };
}

//Deleting MatchMaps
//expects matchmap_id
export function onDeleteMatchMap(payload) {
    const {matchmap_id, mvdmapstat_id, oldData} = payload;

    return function (dispatch, getState) {
        if (!matchmap_id) {
            let newMatchMaps = getState().matches.matchView.matchMaps;
            const index = getState().matches.matchView.matchMaps.indexOf(oldData);
            newMatchMaps.splice(index, 1);
            dispatch({
                type: actions.Matches.GOT_MATCHMAPS,
                payload: {json: newMatchMaps, match_id: getState().matches.matchView.match_id}
            });
           return
        }
        const myInit = {
            method: "DELETE",
            headers: {
                "Authorization": "Token ".concat(getState().account.auth_token),
                "Content-Type": "application/json",
            },
        };
        const url = `https://` + API_HOST_PORT + `/api/${matchmap_id ? 'matchmaps' : 'mvdmapstats'}/${matchmap_id ? matchmap_id : mvdmapstat_id}/`;
        return fetch(url, myInit)
            .then(() => {
                fetch(HTTP_STRING + API_HOST_PORT + "/api/matchmaps?match_id=".concat(getState().matches.matchView.match_id))
                    .then(response => response.json())
                    .then(json => {
                        let preserveMatchMaps = getState().matches.matchView.matchMaps.filter(item => item.id !== matchmap_id && !item.id);
                        dispatch(onDeletingMatchMap());
                        dispatch({
                            type: actions.Matches.SET_EDITPOPUP_ACTIVE_MSG,
                            payload: "Match map report deleted."
                        });
                        if (json.length > 0) {
                            dispatch({
                                type: actions.Matches.UPDATE_MATCH_OBJ,
                                payload: json[0].match
                            });
                        } else {
                            dispatch(onGetMatches());
                            dispatch(onCloseMatchViewPopup());
                        }
                        dispatch({
                            type: actions.Matches.GOT_MATCHMAPS,
                            payload: {json: json, match_id: getState().matches.matchView.match_id}
                        });
                        preserveMatchMaps.forEach(mm => dispatch(updateEditRowData(mm)));
                    });
            });

    }
}


//Updating MatchMaps
//expects matchmap_id
export function onUpdateMatchMap(oldData, newData, resolve, reject) {
    const q = oldData.mvdmapstat.id;
    let bruteForce = false;
    let body = {};
    if ((oldData.score_a !== newData.score_a || oldData.score_b !== newData.score_b || oldData.map !== newData.map)
        || oldData.mvdmapstat.demo_name === 'dummy') {
        bruteForce = true;
    }
    return function (dispatch, getState) {
        if (bruteForce) {
            if (!newData.mvdmapstat.part_1.id || !newData.mvdmapstat.part_2.id || !newData.score_a || !newData.score_b || !newData.map) {
                dispatch(onErrorDialogOpen('Please provide all necessary information.'))
                return reject();
            }
            body = {
                match_id: getState().matches.matchView.match_obj.id,
                part_1: newData.mvdmapstat.part_1.id,
                part_2: newData.mvdmapstat.part_2.id,
                score_a: newData.score_a,
                score_b: newData.score_b,
                map: newData.map,
                report_type: 'brute'
            }
        } else {
            if (!newData.mvdmapstat.part_1.id || !newData.mvdmapstat.part_2.id) {
                dispatch(onErrorDialogOpen('Please select participants.'));
                return reject()
            }
            if (newData.mvdmapstat.part_1.id === newData.mvdmapstat.part_2.id) {
                dispatch(onErrorDialogOpen("Life is hard, there's always a winner and a loser."));
                return reject()
            }
            body = {
                match_id: getState().matches.matchView.match_obj.id,
                part_1: newData.mvdmapstat.part_1.id,
                part_2: newData.mvdmapstat.part_2.id,
            }
        }
        const myInit = {
            method: !q ? "POST" : "GET",
            headers: {
                "Authorization": "Token ".concat(getState().account.auth_token),
                "Content-Type": "application/json",
            },
        };
        let url = !!q ? HTTP_STRING + API_HOST_PORT + "/api/mvdmapstats/" + q + "/" : HTTP_STRING + API_HOST_PORT + "/api/mvdmapstats/";
        return fetch(url, myInit)
            .then(response => response.json())
            .then(json => {
                if (json.id) {
                    myInit['body'] = JSON.stringify(body);
                    myInit['method'] = "POST";
                    return fetch(HTTP_STRING + API_HOST_PORT + "/api/mvdmapstats/" + json.id.toString() + "/process_demo/", myInit)
                        .then(response => response.json())
                        .then(json => {
                            if (!json.mvdmapstat && !json.matchmap) {
                                dispatch(onErrorDialogOpen(json.error.toString() || 'Error updating match map.'));
                                return reject();
                            } else {
                                if (!json.mvdmapstat.matchmap) {
                                    // let mmObj = {
                                    //     'mvdmapstat': json.mvdmapstat,
                                    //     'map': json.mvdmapstat.map,
                                    //     'score_a': null,
                                    //     'score_b': null,
                                    //     'match_id': getState().matches.matchView.match_id
                                    // };
                                    // dispatch(updateEditRowData(mmObj));
                                    dispatch({
                                        type: actions.Matches.SET_EDITPOPUP_ACTIVE_MSG,
                                        payload: "An error occurred when generating this report, please contact staff."
                                    });
                                } else if (!!json.matchmap) {
                                    //force update get_matchmaps readding the not_edited item
                                    let preserveMatchMaps = getState().matches.matchView.matchMaps.filter(item => item.mvdmapstat.demo_name !== json.matchmap.mvdmapstat.demo_name && !item.id);
                                    fetch(HTTP_STRING + API_HOST_PORT + "/api/matchmaps?match_id=".concat(getState().matches.matchView.match_id))
                                        .then(response => response.json())
                                        .then(json => {
                                            if (json.length > 0) {
                                                dispatch({
                                                    type: actions.Matches.UPDATE_MATCH_OBJ,
                                                    payload: json[0].match
                                                });
                                            }
                                            dispatch({
                                                type: actions.Matches.GOT_MATCHMAPS,
                                                payload: {
                                                    json: json,
                                                    match_id: getState().matches.matchView.match_id
                                                }
                                            });
                                            preserveMatchMaps.forEach(mm => dispatch(updateEditRowData(mm)));
                                        });
                                    dispatch({
                                        type: actions.Matches.SET_EDITPOPUP_ACTIVE_MSG,
                                        payload: "Match updated, please verify."
                                    });
                                    return resolve();
                                }
                            }
                        });
                }
            })
    }
}

export function onCloseMatchViewEditPopup(payload) {
    return function (dispatch, getState) {
        fetch(HTTP_STRING + API_HOST_PORT + "/api/matchmaps?match_id=".concat(getState().matches.matchView.match_id))
            .then(response => response.json())
            .then(json => {
                dispatch(onDeletingMatchMap());
                if (json.length > 0) {
                    dispatch({
                        type: actions.Matches.UPDATE_MATCH_OBJ,
                        payload: json[0].match
                    });
                    dispatch(onGetMatches());
                }
                dispatch({
                    type: actions.Matches.GOT_MATCHMAPS,
                    payload: {json: json, match_id: getState().matches.matchView.match_id}
                });
            });
        dispatch({type: actions.Matches.CLOSE_EDIT_REPORT_POPUP, payload:false});
    };
}

export function handleClickSeason(payload) {
    return function (dispatch, getState) {
        dispatch({type: actions.Menu.SET_ACTIVE_SEASON, payload});
        dispatch(onGettingMatches());
        dispatch(onGetMatches());
    }

}

export function onSetReport(payload){
    return function (dispatch, getState){
        if (payload) {

        }
    }
}

//expects match_id
export function onEditReport(payload) {
    return function (dispatch, getState) {
        if (!payload){
            dispatch({type: actions.Matches.CREATE_REPORT, payload});
        } else {
            dispatch({type: actions.Matches.EDIT_REPORT,payload});
        }
        dispatch({type: actions.Matches.OPEN_EDIT_REPORT_POPUP, payload});
    }
}

export function onAddManual() {
    return function (dispatch, getState) {
        const mmObj = {
            mvdmapstat: {
                demo_name: 'dummy',
                part_1: {id: getState().matches.matchView.match_obj.participant_a.id },
                part_2: {id: getState().matches.matchView.match_obj.participant_b.id }
                },
            match_id: getState().matches.matchView.match_id,
            match: {
                participant_a_name:  getState().matches.matchView.match_obj.participant_a_name,
                participant_b_name:  getState().matches.matchView.match_obj.participant_b_name
            }
        };
        dispatch(updateEditRowData(mmObj));
    }
}

export function onDeleteSS(ss_obj) {
    return function (dispatch, getState) {
        const match_id = ss_obj.match;
        const myInit = {
            method: "DELETE",
            headers: {
                "Authorization": "Token ".concat(getState().account.auth_token),
                "Content-Type": "application/json",
            },
        };
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/screenshots/".concat(ss_obj.id).concat("/"), myInit)
            .then(response => {
                dispatch({type: actions.Matches.DELETED_SCREENSHOTS, payload: {}});
                myInit['method'] = "GET";
                return fetch(HTTP_STRING + API_HOST_PORT + "/api/matches/".concat(match_id).concat("/"), myInit)
                    .then(response => response.json())
                    .then(json => {
                            dispatch({type: actions.Matches.UPDATE_MATCH_OBJ, payload: json})
                        }
                    )
            })
    }
}




export function onSaveSS() {
    return function (dispatch, getState) {
        getState().matches.matchView.editPopupState.ssFiles.forEach((ss, index) => {
            let uploadSSFile = new FormData();
            uploadSSFile.append('ss', ss);
            let createSSObj = {match: parseInt(getState().matches.matchView.match_id)};
            const myInit = {
                method: "POST",
                headers: {
                    "Authorization": "Token ".concat(getState().account.auth_token),
                },
            };
            const create = {
                ...myInit, body: JSON.stringify(createSSObj),
                headers: {...myInit.headers, "Content-Type": "application/json"}
            };
            const upload = {
                ...myInit,
                body: uploadSSFile,
                method: "PUT"
            };

            return fetch(HTTP_STRING + API_HOST_PORT + "/api/screenshots/", create)
                .then(response => response.json())
                .then(json => {
                    if (!json.id) {
                        dispatch(onErrorDialogOpen(json.error.toString()));
                        return;
                    }
                    return fetch(HTTP_STRING + API_HOST_PORT + "/api/screenshots/" + json.id.toString() + "/ss/", upload)
                        .then(response => response.json())
                        .then(json => {
                            if (!json.updated) {
                                dispatch(onErrorDialogOpen(json.error.toString() || 'Error uploading ScreenShot '))
                            } else {
                                dispatch({
                                    type: actions.Matches.SET_EDITPOPUP_ACTIVE_MSG,
                                    payload: 'Screenshots uploaded.'
                                });
                                myInit['method'] = "GET";
                                return fetch(HTTP_STRING + API_HOST_PORT + "/api/matches/".concat(json.match.id).concat("/"), myInit)
                                    .then(response => response.json())
                                    .then(json => {
                                            dispatch({type: actions.Matches.UPDATE_MATCH_OBJ, payload: json})
                                        }
                                    )
                            }
                        });
                });
        })
    }
}


//The actual payload already lives on state
export function onSaveMVD() {
    return function (dispatch, getState) {
        getState().matches.matchView.editPopupState.files.forEach((mvd, index) => {
            dispatch(onToggleEditPopupLoaded(false));
            let nMVDs = getState().matches.matchView.editPopupState.files.length;
            let progress = (index + 1).toString() + '/' + nMVDs.toString();
            let uploadMVDFile = new FormData();
            uploadMVDFile.append('mvd', mvd);
            let createMVDObj = {demo_name: mvd.name};
            const myInit = {
                method: "POST",
                headers: {
                    "Authorization": "Token ".concat(getState().account.auth_token),
                },
            };
            const create = {
                ...myInit, body: JSON.stringify(createMVDObj),
                headers: {...myInit.headers, "Content-Type": "application/json"}
            };
            const upload = {
                ...myInit,
                body: uploadMVDFile,
                method: "PUT"
            };
            const process = {
                ...myInit,
                body: JSON.stringify({match_id: getState().matches.matchView.match_id.toString()}),
                headers: {...myInit.headers, "Content-Type": "application/json"}
            };
            return fetch(HTTP_STRING + API_HOST_PORT + "/api/mvdmapstats/", create)
                .then(response => response.json())
                .then(json => {
                    if (!json.id) {
                        dispatch(onErrorDialogOpen(json.error.toString() + ' at ' + progress));
                        dispatch(onToggleEditPopupLoaded(true));
                        return;
                    }
                    return fetch(HTTP_STRING + API_HOST_PORT + "/api/mvdmapstats/" + json.id.toString() + "/mvd/", upload)
                        .then(response => response.json())
                        .then(json => {
                            if (!json.id) {
                                dispatch(onToggleEditPopupLoaded(true));
                                return dispatch(onErrorDialogOpen(json.error.toString() + ' at ' + progress || 'Error uploading MVD ' + progress))
                            } else {
                                dispatch({
                                    type: actions.Matches.SET_EDITPOPUP_ACTIVE_MSG,
                                    payload: progress + ' Demo(s) uploaded, processing demo...'
                                });
                                return fetch(HTTP_STRING + API_HOST_PORT + "/api/mvdmapstats/" + json.id.toString() + "/process_demo/", process)
                                    .then(response => response.json())
                                    .then(json => {
                                        if (!json.mvdmapstat && !json.matchmap) {
                                            dispatch(onErrorDialogOpen(json.error.toString() + ' at ' + progress || 'Error processing MVD ' + progress));
                                            dispatch(onToggleEditPopupLoaded(true));
                                        } else {
                                            if (!json.mvdmapstat.matchmap) {
                                                //Populate mimicked matchmap object to re-render and let user identify participants
                                                //Give style hint for rows without (matchmap_)id
                                                //At material-table: remember to restrict clicking on the attach icon for
                                                // rowdata without mvd field info (forcing delete matchmap/mvdmapstat
                                                // instead of changing the mvd).
                                                let mmObj = {
                                                    'mvdmapstat': json.mvdmapstat,
                                                    'map': json.mvdmapstat.map,
                                                    'score_a': null,
                                                    'score_b': null,
                                                    'match_id': getState().matches.matchView.match_id
                                                };
                                                dispatch(updateEditRowData(mmObj));
                                                dispatch({
                                                    type: actions.Matches.SET_EDITPOPUP_ACTIVE_MSG,
                                                    payload: `[${json.mvdmapstat.demo_name}] - ` + progress + " - Couldn't identify participants from MVD team tags, please assign accordingly."
                                                });
                                                dispatch(onToggleEditPopupLoaded(true));
                                            } else if (!!json.matchmap) {
                                                //force update get_matchmaps
                                                fetch(HTTP_STRING + API_HOST_PORT + "/api/matchmaps?match_id=".concat(getState().matches.matchView.match_id))
                                                    .then(response => response.json())
                                                    .then(json => {
                                                        if (json.length > 0) {
                                                            dispatch({
                                                                type: actions.Matches.UPDATE_MATCH_OBJ,
                                                                payload: json[0].match
                                                            });
                                                        }
                                                        dispatch({
                                                            type: actions.Matches.GOT_MATCHMAPS,
                                                            payload: {
                                                                json: json,
                                                                match_id: getState().matches.matchView.match_id
                                                            }
                                                        });
                                                    });
                                                dispatch({
                                                    type: actions.Matches.SET_EDITPOPUP_ACTIVE_MSG,
                                                    payload: `[${json.mvdmapstat.demo_name}] - ` + progress + " - Match report automatically generated, please verify scores assignment."
                                                });
                                                dispatch(onToggleEditPopupLoaded(true));
                                            }
                                        }
                                    });
                            }
                        })
                })
        });
    }
}

export function onConfirmMatch(payload) {
    const q = payload ? payload.toString() : '';
    return function (dispatch, getState) {
        const myInit = {
            method: "PUT",
            headers: {
                "Authorization": "Token ".concat(getState().account.auth_token),
                "Content-Type": "application/json",
            },
        };
        return fetch(HTTP_STRING + API_HOST_PORT + "/api/matches/" + q + "/confirm_match/", myInit)
            .then(() => {
                return fetch(HTTP_STRING + API_HOST_PORT + "/api/matchmaps?match_id=".concat(q))
                    .then(response => response.json())
                    .then(json => {
                        if (json.length > 0) {
                            dispatch({
                                type: actions.Matches.UPDATE_MATCH_OBJ,
                                payload: json[0].match
                            });
                        }
                        dispatch({
                            type: actions.Matches.GOT_MATCHMAPS,
                            payload: {json: json, match_id: getState().matches.matchView.match_id}
                        });
                    })
            });
    }
}
