import React from 'react';
import {Chat, FlashAuto, Lock, Pets, PowerOff, Schedule} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";


function StatusIcon(props) {
    return (
        props.status === 'brute' ? <Tooltip title="Temporary - no MVD"><PowerOff style={{color: 'rgba(255,255,255,0.2)'}}/></Tooltip>:
            props.status === 'auto' ? <Tooltip title="Automatically Reported"><FlashAuto style={{color: 'rgba(255,255,255,0.2)'}}/></Tooltip>:
                props.status === 'discord' ? <Tooltip title="Reported by Discord Bot"><Chat style={{color: 'rgba(255,255,255,0.2)'}}/></Tooltip>:
                    props.status === 'manual' ? <Tooltip title="Manually Reported"><Pets style={{color: 'rgba(255,255,255,0.2)'}}/></Tooltip>:
                        props.status === 'locked' ? <Tooltip title={props.msg ? props.msg : "Report Confirmed"}><Lock style={{color: 'rgba(255,255,255,0.2)'}}/></Tooltip>:
                            <Tooltip title="Not Reported"><Schedule style={{color: 'rgba(255,255,255,0.2)'}}/></Tooltip>);
}

export default StatusIcon;