import StageGames from "./StageGames";
import React, {Component} from "react";
import {connect} from "react-redux";
import * as actions from "../redux/actions";
import SignIn from "./SignIn";
import ChangePassword from "./ChangePassword";
import NewsPosts from "./NewsPosts/Timeline"
import Profile from "./Profile";
import Registration from "./Registration";
import MatchViewPopup from "./MatchViewPopup";
import Participants from "./Participants";
import Teams from "./Teams";

class ShowContent extends Component {

  // componentDidUpdate() {
  //     this.props.onGettingMatches();
  //     this.props.onGetMatches(this.props.payload);
  // }
  //
  // componentDidMount(){
  //     this.props.onGettingMatches();
  //     this.props.onGetMatches(this.props.payload);
  // }

  render() {
    const {type, payload} = this.props;

    switch (type) {
      case 'teams':
        return (
          <Teams />
        );
      case 'participants':
        return (
          <Participants />
        );
      case 'matches':
        return (
          <StageGames query={payload} />
        );
      case 'SignIn':
        return (
          <SignIn />
        );
      case 'ChangePassword':
        return (
          <ChangePassword />
        );
      case 'news':
        return (
          <NewsPosts />
        );
      case 'Profile':
        return (
          <Profile />
        );
      case 'Registration':
        return (
          <Registration />
        );
      case 'MatchViewPopup':
        return (
          <MatchViewPopup />
        );
      default:
        return (
          <NewsPosts />
        );
    }
  }
}
export default connect(state => {return {...state.menu.activeContent, account: state.account}}, {...actions})(ShowContent);
