import React, {Component, Fragment, } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Root, Header, Nav, Content,} from './Layout';
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import * as actions from "../redux/actions";
import logo from "../logo_season3.png";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {
    DeviceHub, Dns, EmojiPeople,
    ExpandLess,
    ExpandMore, Info,
    Looks3,
    LooksOne,
    LooksTwo,
    Notifications, People,
    PlaylistPlay, Poll, WbSunny
} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import LanguageIcon from '@material-ui/icons/Language';
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import ShowContent from "./ShowContent";
import ErrorDialog from "./ErrorDialog";
import AppBar from "@material-ui/core/AppBar/AppBar";
import ButtonGroup from "@material-ui/core/ButtonGroup";

const styles = theme => ({
    root: {
        //display: 'flex',
    },
    footer: {
        zIndex: theme.zIndex.drawer - 1,
        top: 'auto',
        bottom: 0,
        background: 'linear-gradient(to left, rgba(0,0,0,0) 60%,\n' +
            '              #330066);',
        backgroundColor: '#101010',
        position: 'fixed',
        paddingRight: '0px',
        marginTop: '60px',
        height: '20px'
    },
    appBar: {
        display: 'flex',
        zIndex: theme.zIndex.drawer + 1,
        flexGrow: 1,
        menuButton: {
            marginRight: theme.spacing(2),
        },
        background: 'linear-gradient(to right, rgba(0,0,0,0) 30%,\n' +
            '              #330066), url(' + logo + ');',
        backgroundSize: "contain",
        fallback: {
            //background: theme.palette.primary.main,
            backgroundColor: '#000000'
        },
        backgroundRepeat: "no-repeat",
        backgroundColor: '#000000'
    },
    grow: {
        flexGrow: 1,
        height: '100%'
    },
    content: {
        display: 'flex',
        paddingRight: '0px',
        paddingTop: theme.mixins.toolbar.minHeight + theme.spacing(3),
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: theme.spacing(3),
        marginBottom: '50px'
    },
    toolbar: theme.mixins.toolbar,
    nested: {
        paddingLeft: theme.spacing(4),
    },
});

const config = {
    "navAnchor": "left",
    "navVariant": {
        "xs": "temporary",
        "sm": "temporary",
        "md": "persistent"
    },
    "navWidth": {
        "xs": 220,
        "sm": 220,
        "md": 220
    },
    "collapsible": {
        "xs": false,
        "sm": false,
        "md": false
    },
    "collapsedWidth": {
        "xs": 64,
        "sm": 64,
        "md": 64
    },
    "clipped": {
        "xs": true,
        "sm": true,
        "md": true
    },
    "headerPosition": {
        "xs": "sticky",
        "sm": "sticky",
        "md": "sticky"
    },
    "squeezed": {
        "xs": false,
        "sm": false,
        "md": true
    },
    "footerShrink": {
        "xs": false,
        "sm": false,
        "md": true
    }
};

class App extends Component {

    async componentDidMount() {
        this.setState({drawerOpen: true});
        await this.props.onGetStages();
        if (window.location.hash && window.location.hash.substring(1).replace( /\d+/g, '')) {
            const route = window.location.hash.substring(1).replace( /\d+/g, '');
            if (route === 'report'){
                this.props.setActiveContent({type: 'matches', payload: ''});
            }
            if (route === 'reportDrama'){
                this.props.setActiveContent({type: 'matches', payload: ''});
            }
            if (route === 'registration'){
                this.props.setActiveContent({type: 'Registration'})
            }
            if (route === 'signIn'){
                this.props.setActiveContent({type: 'SignIn'});
            }
        }
    }

    render() {

        if (!this.state) {
            return null
        }

        const {state, onClickMenu, setActiveContent, onLogOut, handleClickMenu, handleCloseMenu, handleClickSeason,} = this.props;
        const setOpen = () => this.setState({...this.state,
            drawerOpen: !this.state.drawerOpen});

        const clickMenu = (e) => handleClickMenu(e.currentTarget);
        const closeMenu = (e) => handleCloseMenu(e.currentTarget);
        const clickSeason = (e) => {
            handleCloseMenu(e.currentTarget);
            handleClickSeason([e.currentTarget.id, e.currentTarget.textContent]);
            setActiveContent({type: 'matches', payload: ''});
        };

        let seasons = [];
        let seasonNames = [];
        let ind = 0;

        let orderedMatches = state.matches.objects.slice().sort((a,b) => {
            if(a.tournament < b.tournament) { return -1; }
            if(a.tournament > b.tournament) { return 1; }
            return 0;
        });
        const {classes} = this.props;

        return (
            <Root config={config}>
                <CssBaseline/>
                <Header setOpen={setOpen}
                        open={this.state.drawerOpen} position="fixed" className={classes.appBar}
                    menuIcon={{
                        inactive: <MenuIcon/>,
                        active: <ChevronLeftIcon/>
                    }}
                >
                    <div className={classes.grow}>
                        <Button disableRipple={true} style={{width: '260px', height:'64px'}} onClick={() => setActiveContent({type: 'Participants'})}>
                            <Fragment/>
                        </Button>
                    </div>
                    {!state.account.auth_token && (
                        <>
                            <ButtonGroup
                                variant="contained"
                                color="primary"
                                size="small"
                                aria-label="large contained secondary button group"
                            >
                                <Button onClick={() => setActiveContent({type: 'SignIn'})}>Login</Button>
                                <Button onClick={() => setActiveContent({type: 'Registration'})}>REGISTER</Button>
                                <Button onClick={clickMenu}>{state.menu.activeSeasonName}</Button>
                            </ButtonGroup>
                            <Menu
                                id="simple-menu"
                                anchorEl={state.menu.anchorEl}
                                keepMounted
                                open={!!state.menu.anchorEl}
                                onClose={closeMenu}
                            >
                                {state.matches && state.matches.objects && state.matches.objects.forEach((obj, i) => {
                                        if (seasons.indexOf(obj.season) < 0) {
                                            seasons.push(obj.season);
                                            seasonNames.push(obj.seasonName)
                                        }
                                    }
                                )
                                }
                                {state.matches && state.matches.objects && seasons.length > 0 && seasons.map(obj => {
                                    ind += 1;
                                    return (
                                        <MenuItem key={obj} id={obj}
                                                  onClick={clickSeason}>{seasonNames[ind-1]}</MenuItem>
                                    )
                                })}
                            </Menu>
                        </>
                    )}

                        {state.account.auth_token && (
                            <>

                                <Typography variant="subtitle2" style={{marginRight: '8px'}}>Welcome, <span
                                    style={{fontWeight: 'bold'}}>{state.account.user_name}! {' '}</span></Typography>
                                <ButtonGroup
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    aria-label="large contained secondary button group"
                                >
                                    <Button onClick={() => setActiveContent({type: 'Profile'})}>Profile</Button>
                                    <Button onClick={() => setActiveContent({type: 'ChangePassword'})}>Change Password</Button>
                                    <Button onClick={() => {
                                        onLogOut();
                                        setActiveContent({type: 'SignIn'})
                                    }}>Logout</Button>
                                    <Button onClick={clickMenu}>{state.menu.activeSeasonName}</Button>
                                </ButtonGroup>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={state.menu.anchorEl}
                                    keepMounted
                                    open={!!state.menu.anchorEl}
                                    onClose={closeMenu}
                                >
                                    {state.matches && state.matches.objects && state.matches.objects.forEach((obj, i) => {
                                            if (seasons.indexOf(obj.season) < 0) {
                                                seasons.push(obj.season);
                                                seasonNames.push(obj.seasonName);
                                            }
                                        }
                                    )
                                    }
                                    {state.matches && state.matches.objects && seasons.length > 0 && seasons.map(obj => {
                                        ind += 1;
                                        return (
                                        <MenuItem key={obj} id={obj}
                                                  onClick={clickSeason}>{seasonNames[ind-1]}</MenuItem>
                                    )})}
                                </Menu>
                        </>)}
                </Header>
                <Nav
                    setOpen={setOpen}
                    open={this.state.drawerOpen}
                    collapsedIcon={{
                        inactive: <ChevronLeftIcon/>,
                        active: <ChevronRightIcon/>
                    }}
                    header={
                        // you can provide fixed header inside nav
                        // change null to some react element
                        ctx => null
                    }
                >
                    <div className={classes.toolbar}/>
                    <List
                        component="nav"
                        className={classes.root}
                    >
                        <ListItem button onClick={() => {setActiveContent({type: 'news', payload: ''});}}>
                            <ListItemIcon>
                                <Notifications/>
                            </ListItemIcon>
                            <ListItemText primary="Announcements"/>
                        </ListItem>
                        <ListItem button onClick={() => {setActiveContent({type: 'teams', payload: ''});}}>
                            <ListItemIcon>
                                <People />
                            </ListItemIcon>
                            <ListItemText primary="Teams"/>
                        </ListItem>
                        {/*<ListItem button onClick={() => setActiveContent({type: 'teams', payload: ''})}>*/}
                        {/*    <ListItemIcon>*/}
                        {/*        <People/>*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText primary="Teams"/>*/}
                        {/*</ListItem>*/}

                        {
                            // FIXME: These objects come from API
                            // continue menu display logic.
                          orderedMatches.filter(o => o.season.toString() === state.menu.activeSeason).sort((a, b) => a.tournament.toLowerCase() > b.tournament.toLowerCase() ? 1 : -1).map((obj, i) => {
                                if (obj.season.toString() === state.menu.activeSeason) {
                                    return (
                                        <Fragment key={i.toString()}>
                                            <ListItem button onClick={() => onClickMenu(i.toString())}>
                                                <ListItemIcon>
                                                    {obj.tournament.toLowerCase().includes("1") ? (<LooksOne/>) : obj.tournament.toLowerCase().includes("2") ?(
                                                        <LooksTwo/>) : obj.tournament.toLowerCase().includes("kenya") || obj.tournament.toLowerCase().includes("open") ?(
                                                        <WbSunny/>) : obj.tournament.toLowerCase().includes("coach") ? (<EmojiPeople/>) : (<LanguageIcon/>)}
                                                </ListItemIcon>
                                                <ListItemText primary={obj.tournament.charAt(0).toUpperCase() + obj.tournament.slice(1).toLowerCase()}/>
                                                {state.menu.menuOpen[i.toString()] ? <ExpandLess/> : <ExpandMore/>}
                                            </ListItem>
                                            <Collapse in={state.menu.menuOpen[i.toString()]} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    {obj.stages.map((stage) => (
                                                            <ListItem key={stage.id.toString()} button
                                                                      onClick={() => {setActiveContent({
                                                                          type: 'matches',
                                                                          payload: stage.id
                                                                      });}} className={classes.nested}>
                                                                <ListItemIcon>
                                                                    {stage.name.toLowerCase().includes("playoff") ? (
                                                                        <DeviceHub style={{transform: 'rotate(90deg)'}}/>) : (<PlaylistPlay/>)}
                                                                </ListItemIcon>
                                                                <ListItemText primary={stage.name}/>
                                                            </ListItem>
                                                    ))
                                                    }
                                                </List>
                                            </Collapse>
                                        </Fragment>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                        <ListItem button onClick={() => {setActiveContent({type: 'matches', payload: ''});}}>
                            <ListItemIcon>
                                <Dns/>
                            </ListItemIcon>
                            <ListItemText primary="Latest Games"/>
                        </ListItem>
                        <ListItem button onClick={() => {setActiveContent({type: 'participants', payload: ''});}}>
                            <ListItemIcon>
                                <Poll/>
                            </ListItemIcon>
                            <ListItemText primary="Standings"/>
                        </ListItem>
                    </List>
                    <Divider/>
                    <List>
                        <ListItem button onClick={() => {setActiveContent({type: 'about', payload: ''});}}>
                            <ListItemIcon>
                                <Info/>
                            </ListItemIcon>
                            <ListItemText primary="About"/>
                        </ListItem>
                    </List>
                </Nav>
                <Content>
                    <div className={classes.content}>
                        <div>
                            <ShowContent/>
                            <ErrorDialog />
                        </div>
                    </div>
                </Content>
                <AppBar className={classes.footer}>
                    <Typography variant="caption" style={{color: '#9f9f9f', textAlign:"right", paddingRight:"20px"}}>GetQuad Admin v0.01-alpha (c) Copyright 2019 GetQuad crew.</Typography>
                </AppBar>
            </Root>
        );
    }
}


const styled = withStyles(styles)(App);
export default connect(state => {
    return {state: state}
}, {...actions})(styled);
