import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import TimelineLoaded from './TimelineLoaded';
import TimelineLoading from './TimelineLoading';
import Grid from '@material-ui/core/Grid';
import ImageGallery from 'react-image-gallery';

export class Timeline extends Component {
  page = 1;

  componentDidMount() {
    this.props.onGetAnnouncements({ page: this.page.toString(), page_size: '5' });
  }

  componentWillUnmount() {
    this.props.clearAnnouncements();
  }

  handleLoadMore = () => {
    this.page = this.page + 1;
    this.props.onGetAnnouncements({ page: this.page.toString(), page_size: '5' });
  };

  render() {
    const { announcements, matches } = this.props.state;
    const images = (matches.matchView.match_obj.screenshot_set || []).map((f) => ({
      original: f.ss.replace('http', 'https').replace(':8000', ''),
    }));
    return (
      <>
        {!announcements.loaded ? (
          <TimelineLoading />
        ) : (
          <Fragment>
            {!!matches.matchView.match_obj && !!matches.matchView.match_obj.screenshot_set && (
              <ImageGallery
                items={images}
                infinite={false}
                showThumbnails={false}
                lazyLoad={true}
                showPlayButton={false}
              />
            )}

            <TimelineLoaded
              //match_id={matches.matchView.match_id}
              events={announcements.items}
              total={announcements.count}
              handleLoadMore={this.handleLoadMore}
              isAnnouncement={!matches.matchView.match_obj || !matches.matchView.match_obj.screenshot_set}
              canPost={
                !!this.props.state.account.is_tournament_admin ||
                (!!this.props.state.account.user_id &&
                  !!matches.matchView.match_obj &&
                  !!matches.matchView.match_obj.screenshot_set)
              }
            />
          </Fragment>
        )}
      </>
    );
  }
}

export default connect(
  (state) => {
    return { state: { ...state } };
  },
  { ...actions }
)(Timeline);
