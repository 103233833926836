import React, {Component} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import {withStyles} from "@material-ui/styles";
import {connect} from "react-redux";
import * as actions from "../redux/actions";
import {Typography} from "@material-ui/core";

const useStyles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    input: {
        margin: theme.spacing(1),
    },
});

class Profile extends Component {
    render() {

        const { classes, state } = this.props;

        return (
            <div className={classes.container}>
                <form>
                    <List style={{alignContent: 'right'}}>
                        <ListItem>
                            <Typography>User Name </Typography>
                            <Input
                                value={state.account.user_name}
                                className={classes.input}
                                disabled
                                inputProps={{
                                    'aria-label': 'description',
                                }}
                            />
                            <Typography>e-mail </Typography>
                            <Input
                                placeholder="email"
                                disabled
                                value={state.account.user_email}
                                className={classes.input}
                                inputProps={{
                                    'aria-label': 'description',
                                }}
                            />
                        </ListItem>
                    </List>
                </form>
            </div>
        );
    }
}

const styled = withStyles(useStyles)(Profile);
export default connect(state => { return {state: state} }, {...actions})(styled);