import React from 'react';
import MuiAvatar from '@material-ui/core/Avatar';
import md5 from 'blueimp-md5';


export default function AvatarView ({user}) {
    return (
        <MuiAvatar
            src={
                user
                    ? `https://www.gravatar.com/avatar/${md5(user.email)}?d=retro`
                    : `https://www.gravatar.com/avatar/?d=mp`
            }
        />
    );
}
