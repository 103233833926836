import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import EventList from './EventList';
import groupByDay from './groupByDay';
import TextField from "@material-ui/core/TextField";
import PostNews from "./PostNews";
import Link from "@material-ui/core/Link";

const styles = theme => ( {
    // '@global': {
    //     body: {
    //         background: theme.palette.background.default
    //     },
    // },


    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginRight: '2px',
        marginLeft: '2px',
        marginBottom: '60px',
    },
});

const getDayString = date =>
    new Date(date).toLocaleDateString('en', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

const TimelineLoadedView = ({
    events = [],
    handleLoadMore,
    total,
    classes,
    canPost,
    isAnnouncement,
}) => {
    const { days, eventsByDay } = groupByDay(events);

    if (days.length === 0) {
        return (
        <div className={classes.root}>
            {canPost && (
                <PostNews />
            )}
            {!canPost && !isAnnouncement &&(
                <Typography variant={"h6"}><br/>Please <Link href={"/?u=" + Math.random().toString(36).substring(7) +"#registration"}>register</Link> or <Link href={"/?u=" + Math.random().toString(36).substring(7) +"#signIn"}>sign-in</Link> in order to post.<br/></Typography>
            )}
	</div>
	);
    }
    return (
        <div className={classes.root}>
            {canPost && (
                <PostNews />
            )}
            {!canPost && !isAnnouncement &&(
                <Typography variant={"h6"}><br/>Please <Link href={"/?u=" + Math.random().toString(36).substring(7) +"#registration"}>register</Link> or <Link href={"/?u=" + Math.random().toString(36).substring(7) +"#signIn"}>sign-in</Link> in order to post.<br/></Typography>
            )}
            {days.map(day => (
                <div key={day} style={{width: '100%', maxWidth: '600px'}}>
                    <Typography style={{marginLeft: '5px'}} variant="subtitle1" gutterBottom>
                        {getDayString(day)}
                    </Typography>
                    <EventList events={eventsByDay[day]} />
                </div>
            ))}
            {events.length < total && (
                <Button variant="contained"
                        color="primary"
                        type="submit" onClick={handleLoadMore}>
                    Load more
                </Button>
            )}
        </div>
    );
};

const TimelineLoaded = withStyles(styles)(TimelineLoadedView);

export default TimelineLoaded;
